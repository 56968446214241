import classnames from "classnames";
import moment from "moment-timezone";
import React from "react";

import style from "./style.module.css";

function getDuration(t, targetUtcDateStr, endUtcDateStr) {
  const now = moment().tz(
    Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || moment.tz.guess()
  );
  const targetDateStr = moment
    .utc(targetUtcDateStr, "YYYY-MM-DD HH:mm:ss")
    .tz(
      Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || moment.tz.guess()
    )
    .format("YYYY-MM-DD HH:mm:ss");
  const endDateStr = moment
    .utc(endUtcDateStr, "YYYY-MM-DD HH:mm:ss")
    .tz(
      Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || moment.tz.guess()
    )
    .format("YYYY-MM-DD HH:mm:ss");
  const startDate = moment(targetDateStr, "YYYY-MM-DD HH:mm:ss");
  const endDate = moment(endDateStr, "YYYY-MM-DD HH:mm:ss");
  let tooltipDate = moment(startDate, [
    "YYYY-MM-DD HH:mm:ss",
    "YYYY-MM-DD",
  ]).format(t("dateFormat").toUpperCase());
  let tooltipTime = moment(startDate, ["YYYY-MM-DD HH:mm:ss", "HH:mm"]).format(
    "HH:mm"
  );

  if (now.isBetween(startDate, endDate)) {
    return {
      textColor: "#fff",
      backgroundColor: "var(--color-streaming-tag)",
      message: t("Live Tag"),
      tooltipDate,
      tooltipTime,
    };
  }

  const isDateAfterNow = startDate.isAfter(now);
  let diffDays = "";

  if (isDateAfterNow) {
    diffDays = startDate.diff(now, "days", true);
  } else {
    diffDays = now.diff(endDate, "days", true);
  }
  const diffHours = (diffDays % 1) * 24;
  const diffMinutes = (diffHours % 1) * 60;

  let result = "";
  let unit = "";
  if (diffDays >= 1) {
    unit = `live_even_unit_day${days !== 1 ? "s" : ""}`;
    const days = Math.floor(diffDays);
    result += days;
  } else if (diffHours >= 1) {
    unit = `live_even_unit_hour${hours !== 1 ? "s" : ""}`;
    const hours = Math.floor(diffHours);
    result += hours;
  } else {
    unit = `live_even_unit_minute${minutes !== 1 ? "s" : ""}`;
    const minutes = Math.round(diffMinutes);
    result += minutes;
  }
  if (isDateAfterNow) {
    return {
      textColor: "#fff",
      backgroundColor: "var(--color-upcoming-tag)",
      message: t("Live Event Upcomming", { duration: result }) + " " + t(unit),
      tooltipDate,
      tooltipTime,
    };
  } else {
    tooltipDate = moment(endDate, ["YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD"]).format(
      t("dateFormat").toUpperCase()
    );
    let tooltipTime = moment(endDate, ["YYYY-MM-DD HH:mm:ss", "HH:mm"]).format(
      "HH:mm"
    );
    return {
      textColor: "#000",
      backgroundColor: "var(--color-ended-tag)",
      message: t("Live Event Ended", { duration: result, unit: t(unit) }),
      tooltipDate,
      tooltipTime,
    };
  }
}

function LiveEventTag({ startDate, endDate, containerStyle, t, withToolTip }) {
  const currentDurration = getDuration(t, startDate, endDate);

  return (
    <>
      <div
        className={classnames(
          style.container,
          {
            [style.tooltip]: withToolTip,
          },
          containerStyle
        )}
        style={{
          color: currentDurration.textColor,
          backgroundColor: currentDurration.backgroundColor,
        }}
        data-tool-tip={t("Live Date", {
          date: currentDurration.tooltipDate,
          time: currentDurration.tooltipTime,
        })}
      >
        <p className={style.message}>{currentDurration.message}</p>
      </div>
      {!withToolTip && (
        <>
          <p
            className={style.message}
            style={{
              color: "#77ab45",
            }}
          >
            {t("Live Date", {
              date: currentDurration.tooltipDate,
              time: currentDurration.tooltipTime,
            })}
          </p>
        </>
      )}
    </>
  );
}

export default LiveEventTag;
