import Image, { ImageRatio, PlaceholderType } from "components/Image";
import { LinkDuo } from "components/LinkDuo";
import consts from "consts/consts";
import { getFromLocal } from "helpers/localStorage";
import { getRealSlug } from "helpers/url";
import { applyBlur } from "helpers/utility";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { TucanoModels } from "web-api/main";

import style from "./style.module.css";

class SlideBanner extends Component {
  render() {
    const { asset, liveEPG, profile } = this.props;
    let type;
    if (
      asset.getType() === "asset_vod-background" ||
      asset.getType() === "category_vod-background" ||
      asset.getType() === consts.imageTypes.movie_episode_Poster ||
      asset.getType() === consts.imageTypes.movie_episode_cover
    ) {
      type = "vod";
    }
    if (
      asset.getType() === "asset_vod" ||
      asset.getType() === consts.imageTypes.catchupCover
    ) {
      type = "catchup";
    }
    if (
      asset.getType() === "channel_logo-vertical" ||
      asset.getType() === "channel_logo"
    ) {
      type = "live";
    }
    if (asset.getType() === "link") {
      type = "link";
    }
    if (asset.getType() === "EPGBroadcast") {
      type = "epgEvent";
    }
    let assetLiveId, assetLive;
    if (liveEPG) {
      assetLive = liveEPG.find((item) => {
        if (item.channelId === asset.getId()) {
          return true;
        } else {
          return undefined;
        }
      });
    }
    if (assetLive) {
      assetLiveId = assetLive.getEpgId();
    }
    let url = consts.routes.movieAsset.url.replace(":id", asset.getId());

    if (type === "live") {
      url = consts.routes.playerlive.url.replace(":channelId", asset.getId());
    }

    if (
      asset instanceof TucanoModels.Asset &&
      asset.isCategory &&
      asset.isCategory === true
    ) {
      if (asset.isTVShow() === true || asset.isSerie === true) {
        if (asset.isAssetTVShowEpisode) {
          url = consts.routes.tvshowAsset.url.replace(
            ":id",
            asset.getParentId()
          );
        } else {
          url = consts.routes.tvshowAsset.url.replace(":id", asset.getId());
        }
      } else {
        url = consts.routes.vod.detailUrl.replace(":id", asset.getId());
      }
    }
    if (asset.isCategory === false && asset.getParentId() !== undefined) {
      if (
        asset.isAssetTVShow === false &&
        asset.isAssetTVShowEpisode === true
      ) {
        url = consts.routes.tvshowAsset.url.replace(":id", asset.getParentId());
      }
    }
    if (type === "epgEvent") {
      url = consts.routes.epgAsset.url.replace(":id", assetLiveId);
    }
    if (
      asset instanceof TucanoModels.Asset &&
      !asset.isCategory &&
      asset.isAssetTVShow === true
    ) {
      if (asset.isSerie === true) {
        url = consts.routes.tvshowAsset.url.replace(":id", asset.getId());
      } else {
        url = consts.routes.vod.detailUrl.replace(":id", asset.getId());
      }
    }

    if (type === "link") {
      url = getRealSlug(asset.getUrl());
    }

    return (
      <div className={style.root}>
        <div className={style.container}>
          {type !== "link" && (
            <Link to={url}>
              <Image
                src={asset.getImage(consts.image_hostname, {
                  width: 1920,
                  height: 1080,
                  languageId: getFromLocal("languageId"),
                })}
                width={"100%"}
                height={"100%"}
                ratio={ImageRatio.BANNER}
                placeholder={PlaceholderType.PRIMARY}
                blur={applyBlur(asset, profile)}
              />
            </Link>
          )}
          {type === "link" && (
            <LinkDuo to={url} className={style.container}>
              <Image
                src={asset.getImage(consts.image_hostname, {
                  width: 1280,
                  height: 320,
                  languageId: getFromLocal("languageId"),
                })}
                width={"100%"}
                height={"100%"}
                ratio={ImageRatio.BANNER}
                placeholder={PlaceholderType.PRIMARY}
                blur={applyBlur(asset, profile)}
              />
            </LinkDuo>
          )}
        </div>
      </div>
    );
  }
}

SlideBanner.defaultProps = {
  asset: {},
  thumbnailSize: {},
};

SlideBanner.propTypes = {
  asset: PropTypes.object,
  thumbnailSize: PropTypes.object,
};

export default compose(
  connect((state) => {
    return {
      isConnected: state.session.customerAuthToken !== undefined,
    };
  })
)(SlideBanner);
