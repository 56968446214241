import ThumbnailCoverLibrary from "components/ThumbnailCoverLibrary";
import ThumbnailPoster from "components/ThumbnailPoster";
import consts from "consts/consts";
import { ResponsiveType } from "consts/responsive";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { TucanoModels } from "web-api/main";

import style from "./style.module.css";

class ThumbnailLibrary extends Component {
  subscriptionModal(asset) {
    if (this.props.subscriptionModal) {
      this.props.subscriptionModal(asset);
    }
  }
  render() {
    const { asset, thumbnailSize, t, viewport, isLocked, profile } = this.props;
    const isChannel = asset instanceof TucanoModels.Channel;
    let url;
    if (asset) {
      url = consts.routes.movieAsset.url.replace(":id", asset.getId());
    }
    let synSize = 100;
    if (viewport.type === ResponsiveType.PHONE) {
      synSize = 70;
    }
    if (viewport.type === ResponsiveType.TABLET) {
      synSize = 40;
    }
    if (isChannel) {
      url = consts.routes.playerlive.url.replace(":channelId", asset.getId());
    } else if (!asset.isCatchup() && !asset.getCatchupId()) {
      if (asset.isTVShow()) {
        url = consts.routes.tvshowAsset.url.replace(":id", asset.getId());
      } else if (asset.isTVShowEpisode()) {
        url = consts.routes.player.url.replace(":assetId", asset.getId());
      }
    }

    const valid = this.props.asset;
    if (isChannel) {
      valid.type = consts.imageTypes.tvChannel;
    } else if (valid.type === consts.imageTypes.movie_episode_cover) {
      valid.type = consts.imageTypes.movie_episode_Poster;
    }
    return (
      <div
        className={
          consts.designMode == 169 ? style.containerCover : style.container
        }
      >
        {consts.designMode == 169 ? (
          <ThumbnailCoverLibrary
            asset={valid}
            imageType={
              isChannel
                ? consts.imageTypes.tvChannel
                : valid.isAssetTVShow && !valid.isAssetTVShowEpisode
                ? consts.imageTypes.serieCover
                : consts.imageTypes.movie_episode_cover
            }
            rootClassName={style.thumbnailCover}
            size={thumbnailSize}
            onlyPlay={true}
            subscriptionModal={this.subscriptionModal.bind(this)}
            isLocked={isLocked}
            profile={profile}
          />
        ) : (
          <ThumbnailPoster
            asset={valid}
            rootClassName={style.thumbnail}
            size={thumbnailSize}
            onlyPlay={true}
            subscriptionModal={this.subscriptionModal.bind(this)}
            isLocked={isLocked}
            profile={profile}
          />
        )}
        <div className={style.description}>
          {!isChannel &&
            !valid.isTVShow?.() &&
            (!!valid.parentTitle ||
              !!valid.getSeasonNumber() ||
              !!valid.getEpisodeNumber()) && (
              <Link to={url} className={style.parentTitle}>
                {!!valid.parentTitle && `${valid.parentTitle}  `}
                <span className={style.subtitle}>
                  {!!valid.getSeasonNumber() &&
                    `${t("S")}${valid.getSeasonNumber()} - `}
                  {!!valid.getEpisodeNumber() &&
                    `${t("EP")}${valid.getEpisodeNumber()}`}
                </span>
              </Link>
            )}
          <Link to={url} className={style.title}>
            {isChannel ? valid.getName() : valid.getTitle()}
          </Link>
          {isChannel ? (
            <p className={style.subtitle}>
              {`${moment.utc(valid.rightStart).local().format("HH:mm")} ${t(
                "to"
              )} ${moment.utc(valid.rightEnd).local().format("HH:mm")} ${
                !!valid.genre && `| ${valid.genre}`
              } ${!!valid.moralityLevel && `| +${valid.moralityLevel}`}`}
            </p>
          ) : (
            <>
              {valid.isTVShow?.() ? (
                <>
                  {valid.isTVShowEpisode()
                    ? (!!valid.getEpisodeNumber() ||
                        !!valid.getEpisodeNumber()) && (
                        <p className={style.subtitle}>
                          {!!valid.getSeasonNumber() &&
                            `${t("S")} ${valid.getSeasonNumber()} - `}
                          {!!valid.getEpisodeNumber() &&
                            `${t("EP")} ${valid.getEpisodeNumber()}`}
                        </p>
                      )
                    : !!valid.getSeasonCount() && (
                        <p className={style.subtitle}>
                          {t("Season", { count: valid.getSeasonCount() })}
                        </p>
                      )}
                </>
              ) : (
                <p className={style.subtitle}>
                  {valid.getReleaseYear()}{" "}
                  {valid.getReleaseYear() && valid.getGenre() && "|"}{" "}
                  {valid.getGenre()}
                </p>
              )}
            </>
          )}
          {!isChannel ? (
            <p className={style.expireDate}>
              {`${t("Expire_on")} ${moment
                .utc(valid.getValidityEndDate())
                .local()
                .format(consts.dateFormat)}`}
            </p>
          ) : (
            <p className={style.expireDate}>
              {`${t("Expire_on")} ${moment
                .utc(valid.rightEnd)
                .local()
                .format(consts.dateFormat)}`}
            </p>
          )}
          {valid.getSynopsis() && consts.designMode !== 169 && (
            <p className={style.synopsis}>
              {valid.getSynopsis().substring(0, synSize)}...
            </p>
          )}
        </div>
      </div>
    );
  }
}

ThumbnailLibrary.defaultProps = {
  asset: {},
  rootClassName: "",
  size: {},
  isLocked: false,
};

ThumbnailLibrary.propTypes = {
  asset: PropTypes.object,
  rootClassName: PropTypes.string,
  size: PropTypes.object,
};

export default withTranslation()(ThumbnailLibrary);
