import * as tucanoConsts from "../consts/tucano";
import { generateImageUrl, generatePosterImageUrl } from "../helpers/imageHelper";
export default class TVShow {
  constructor(item, itemType) {
    // console.log("TVShow input", item);
    this.id = item.idCatalog;
    this.rating = null;
    this.type = tucanoConsts.ImageType.VOD_CATEGORY_THUMBNAIL;
    this.title = item.name;
    this.seasonCount = item.categories.length;
    this.synopsis = item.synopsis;
    this.isSerie = item.serie === true;
    this.catalog = item.catalog;
    this.flyImage = undefined;
    if (item.rating) {
      this.score = item.rating.score;
      this.averageScore = item.rating.average;
    }
    if (item.directMetadata) {
      if (item.directMetadata.AD_LIFLIES && item.directMetadata.AD_LIFLIES.length > 0) {
        this.flyImage = item.directMetadata.AD_LIFLIES[0];
      }
    }
    this.adult = item.adult;
  }

  getScore() {
    return this.score;
  }
  getCatalogue() {
    return this.catalog;
  }
  getAverageScore() {
    let result = undefined;
    if (!this.averageScore) {
      result = 0;
    }
    if (Number.isInteger(this.averageScore)) {
      result = this.averageScore;
    }
    if (!result && result !== 0) {
      result = parseFloat(this.averageScore).toFixed(2);
    }
    if (result > 5) {
      result = 5;
    }
    return result;
  }

  getId() {
    return this.id;
  }

  isTVShow() {
    return true;
  }

  isSerie() {
    return this.serie;
  }

  getType() {
    return this.type;
  }

  getTitle() {
    return this.title;
  }

  getSeasonCount() {
    return this.seasonCount;
  }

  isCatchup() {
    return false;
  }

  getSynopsis() {
    return this.synopsis;
  }

  getPosterImage(domain = "/", options) {
    return `${domain}${generatePosterImageUrl(this, options)}`;
  }

  getImage(domain = "/", options) {
    return `${domain}${generateImageUrl(this, options)}`;
  }

  get isAdult() {
    return this.adult;
  }
}
