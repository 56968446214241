import ContentWrapper from "components/ContentWrapper";
import Grid from "components/Grid";
import Header from "components/Header";
import Sad from "components/Icons/Sad";
import { ImageRatio } from "components/Image";
import LinkBack from "components/LinkBack";
import Loader from "components/Loader";
import ThumbnailSearchEPG from "components/ThumbnailSearchEPG";
import consts from "consts/consts";
import responsiveConf from "consts/responsive";
import { getFromLocal } from "helpers/localStorage";
import { removeSpace } from "helpers/url";
import get from "lodash.get";
import React, { Component, Fragment } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { compose } from "redux";
import { ASSET_TYPE, getImageType } from "web-api/consts/tucano";
import { TucanoActions, TucanoSelectors } from "web-api/main";

import style from "./style.module.css";

class PageSearch extends Component {
  state = { selectedTab: 0, initialLoad: false };

  componentDidMount() {
    const { t } = this.props;
    document.title = t(this.props.route.title);
    if (consts.appTitle) {
      document.title = `${consts.appTitle} - ${t(this.props.route.title)}`;
    }
    const term = get(this.props.match, "params.term", "");
    const urlSearchParams = new URLSearchParams(location.search);
    const searchField = urlSearchParams.get("searchField") || "fts";
    this.props.dispatch(
      TucanoActions.search(getFromLocal("languageId"), term, searchField)
    );
    this.setState({ initialLoad: true });
    this.selectActiveTabFromHash();
  }

  componentDidUpdate(prevProps) {
    this.selectActiveTabFromHash(prevProps.searchResults);
  }

  selectActiveTabFromHash(previousSearchResults = undefined) {
    const { searchResults } = this.props;
    const activeHash = window.location.hash.substr(1);
    let activeTab = 0;
    if (searchResults && previousSearchResults === undefined) {
      if (searchResults.live) {
        activeTab += 1;
        if (activeHash === "now") {
          this.setState({ selectedTab: activeTab });
        }
      }
      if (searchResults.catchup) {
        activeTab += 1;
        if (activeHash === "catchup") {
          this.setState({ selectedTab: activeTab });
        }
      }
      if (searchResults.vod) {
        activeTab += 1;
        if (activeHash === "vod") {
          this.setState({ selectedTab: activeTab });
        }
      }
      if (searchResults.epg) {
        activeTab += 1;
        if (activeHash === "epg") {
          this.setState({ selectedTab: activeTab });
        }
      }
    }
  }
  subscriptionModal(asset) {
    if (this.props.subscriptionModal) {
      this.props.subscriptionModal(asset);
    }
  }
  render() {
    const {
      searchResults,
      isConnected,
      isLoading,
      match,
      viewport,
      totalSearchResults,
      t,
      activeProfile,
    } = this.props;
    const { initialLoad } = this.state;

    const term = get(match, "params.term", "");

    const thumbnailSize = responsiveConf.find((item) => {
      return item.name === viewport.type;
    });

    return (
      <Fragment>
        <ContentWrapper>
          {!initialLoad && <Loader centered={true} />}
          {initialLoad && (
            <div className={style.root}>
              <LinkBack rootClassName={style.backLink} />
              {term.length < 3 && (
                <p className={style.errorMessage}>
                  <Trans t={t}>
                    Your search must contain at least 3 characters
                  </Trans>
                </p>
              )}
              {term.length >= 3 && (
                <Fragment>
                  {isLoading && <Loader centered={true} />}
                  {!isLoading && (
                    <Fragment>
                      <Header>
                        {t("Search results for", { term: removeSpace(term) })}{" "}
                      </Header>
                      {searchResults && (
                        <Tabs
                          className={style.tabsContainer}
                          selectedIndex={this.state.selectedTab}
                          onSelect={(tabIndex) =>
                            this.setState({ selectedTab: tabIndex })
                          }
                        >
                          <TabList>
                            <Tab>{t("All")}</Tab>
                            {searchResults.live &&
                              searchResults.live.length > 0 && (
                                <Tab>
                                  <Trans t={t}>On now</Trans>
                                </Tab>
                              )}
                            {searchResults.catchup &&
                              searchResults.catchup.length > 0 && (
                                <Tab>
                                  <Trans t={t}>Catch-Up</Trans>
                                </Tab>
                              )}
                            {searchResults.vod && searchResults.vod.length > 0 && (
                              <Tab>
                                <Trans t={t}>On demand</Trans>
                              </Tab>
                            )}
                            {searchResults.epg && searchResults.epg.length > 0 && (
                              <Tab>
                                <Trans t={t}>Programs</Trans>
                              </Tab>
                            )}
                          </TabList>
                          <TabPanel>
                            {totalSearchResults === 0 && (
                              <div className={style.noResults}>
                                <Sad className={style.noResultsIcon} />
                                <p>
                                  <Trans t={t}>No results</Trans>
                                </p>
                              </div>
                            )}
                            {searchResults.live &&
                              searchResults.live.length > 0 && (
                                <Fragment>
                                  <p className={style.sectionTitle}>
                                    <Trans t={t}>On now</Trans>
                                  </p>
                                  <Grid rootClassName={style.gridLandscape}>
                                    {searchResults.live
                                      .filter(Boolean)
                                      .map((item, index) => {
                                        return (
                                          <ThumbnailSearchEPG
                                            key={index}
                                            dispalyFavorite={false}
                                            item={item}
                                            size={thumbnailSize}
                                            ratio={ImageRatio.COVER}
                                            isConnected={isConnected}
                                            subscriptionModal={this.subscriptionModal.bind(
                                              this
                                            )}
                                            profile={activeProfile}
                                          />
                                        );
                                      })}
                                  </Grid>
                                </Fragment>
                              )}
                            {searchResults.catchup &&
                              searchResults.catchup.length > 0 && (
                                <Fragment>
                                  <p className={style.sectionTitle}>
                                    <Trans t={t}>Catch-Up</Trans>
                                  </p>
                                  <Grid rootClassName={style.gridLandscape}>
                                    {searchResults.catchup
                                      .filter(Boolean)
                                      .map((item, index) => {
                                        return (
                                          <ThumbnailSearchEPG
                                            key={index}
                                            dispalyFavorite={false}
                                            item={item}
                                            size={thumbnailSize}
                                            ratio={ImageRatio.COVER}
                                            isConnected={isConnected}
                                            subscriptionModal={this.subscriptionModal.bind(
                                              this
                                            )}
                                            profile={activeProfile}
                                          />
                                        );
                                      })}
                                  </Grid>
                                </Fragment>
                              )}
                            {searchResults.vod && searchResults.vod.length > 0 && (
                              <Fragment>
                                <p className={style.sectionTitle}>
                                  <Trans t={t}>On demand</Trans>
                                </p>
                                <Grid
                                  rootClassName={
                                    consts.designMode === 169
                                      ? style.gridLandscape
                                      : style.gridPortrait
                                  }
                                >
                                  {searchResults.vod
                                    .filter(Boolean)
                                    .map((item, index) => {
                                      return (
                                        <ThumbnailSearchEPG
                                          key={index}
                                          item={item}
                                          dispalyFavorite={false}
                                          size={thumbnailSize}
                                          ratio={
                                            consts.designMode === 169
                                              ? ImageRatio.COVER
                                              : ImageRatio.POSTER
                                          }
                                          imageType={
                                            item.isAssetTVShow
                                              ? getImageType(
                                                  consts.designMode,
                                                  ASSET_TYPE.VOD_CATEGORY
                                                )
                                              : getImageType(consts.designMode)
                                          }
                                          isConnected={isConnected}
                                          subscriptionModal={this.subscriptionModal.bind(
                                            this
                                          )}
                                          profile={activeProfile}
                                        />
                                      );
                                    })}
                                </Grid>
                              </Fragment>
                            )}
                            {searchResults.epg && searchResults.epg.length > 0 && (
                              <Fragment>
                                <p className={style.sectionTitle}>
                                  <Trans t={t}>Programs</Trans>
                                </p>
                                <Grid rootClassName={style.gridLandscape}>
                                  {searchResults.epg
                                    .filter(Boolean)
                                    .map((item, index) => {
                                      return (
                                        <ThumbnailSearchEPG
                                          key={index}
                                          item={item}
                                          dispalyFavorite={false}
                                          size={thumbnailSize}
                                          ratio={ImageRatio.COVER}
                                          isConnected={isConnected}
                                          subscriptionModal={this.subscriptionModal.bind(
                                            this
                                          )}
                                          profile={activeProfile}
                                        />
                                      );
                                    })}
                                </Grid>
                              </Fragment>
                            )}
                          </TabPanel>
                          {searchResults.live && searchResults.live.length > 0 && (
                            <TabPanel>
                              <Grid rootClassName={style.gridLandscape}>
                                {searchResults.live.map((item, index) => {
                                  return (
                                    <ThumbnailSearchEPG
                                      key={index}
                                      dispalyFavorite={false}
                                      item={item}
                                      size={thumbnailSize}
                                      ratio={ImageRatio.COVER}
                                      isConnected={isConnected}
                                      subscriptionModal={this.subscriptionModal.bind(
                                        this
                                      )}
                                      profile={activeProfile}
                                    />
                                  );
                                })}
                              </Grid>
                            </TabPanel>
                          )}
                          {searchResults.catchup &&
                            searchResults.catchup.length > 0 && (
                              <TabPanel>
                                <Grid rootClassName={style.gridLandscape}>
                                  {searchResults.catchup.map((item, index) => {
                                    return (
                                      <ThumbnailSearchEPG
                                        key={index}
                                        item={item}
                                        dispalyFavorite={false}
                                        size={thumbnailSize}
                                        ratio={ImageRatio.COVER}
                                        isConnected={isConnected}
                                        subscriptionModal={this.subscriptionModal.bind(
                                          this
                                        )}
                                        profile={activeProfile}
                                      />
                                    );
                                  })}
                                </Grid>
                              </TabPanel>
                            )}
                          {searchResults.vod && searchResults.vod.length > 0 && (
                            <TabPanel>
                              <Grid
                                rootClassName={
                                  consts.designMode === 169
                                    ? style.gridLandscape
                                    : style.gridPortrait
                                }
                              >
                                {searchResults.vod.map((item, index) => {
                                  return (
                                    <ThumbnailSearchEPG
                                      key={index}
                                      item={item}
                                      dispalyFavorite={false}
                                      size={thumbnailSize}
                                      ratio={
                                        consts.designMode === 169
                                          ? ImageRatio.COVER
                                          : ImageRatio.POSTER
                                      }
                                      imageType={
                                        item.isAssetTVShow
                                          ? getImageType(
                                              consts.designMode,
                                              ASSET_TYPE.VOD_CATEGORY
                                            )
                                          : getImageType(consts.designMode)
                                      }
                                      isConnected={isConnected}
                                      subscriptionModal={this.subscriptionModal.bind(
                                        this
                                      )}
                                      profile={activeProfile}
                                    />
                                  );
                                })}
                              </Grid>
                            </TabPanel>
                          )}
                          {searchResults.epg && searchResults.epg.length > 0 && (
                            <TabPanel>
                              <Grid rootClassName={style.gridLandscape}>
                                {searchResults.epg.map((item, index) => {
                                  return (
                                    <ThumbnailSearchEPG
                                      key={index}
                                      dispalyFavorite={false}
                                      item={item}
                                      size={thumbnailSize}
                                      ratio={ImageRatio.COVER}
                                      isConnected={isConnected}
                                      subscriptionModal={this.subscriptionModal.bind(
                                        this
                                      )}
                                      profile={activeProfile}
                                    />
                                  );
                                })}
                              </Grid>
                            </TabPanel>
                          )}
                        </Tabs>
                      )}
                    </Fragment>
                  )}
                </Fragment>
              )}
            </div>
          )}
        </ContentWrapper>
      </Fragment>
    );
  }
}

export default compose(
  connect((state) => {
    return {
      isConnected: state.session.customerAuthToken !== undefined,
      isLoading: state.search.loading,
      searchResults: TucanoSelectors.getSearchResults(state),
      totalSearchResults: TucanoSelectors.getTotalSearchResults(state),
      viewport: state.ui.viewport,
      activeProfile: TucanoSelectors.getActiveProfile(state),
    };
  }),
  withTranslation()
)(PageSearch);
