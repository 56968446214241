import ContentWrapper from "components/ContentWrapper";
import Header from "components/Header";
import Loader from "components/Loader";
import Slide from "components/Slide";
import SlideShow from "components/SlideShow";
import Strate from "components/Strate";
import ThumbnailContinueWatching from "components/ThumbnailContinueWatching";
import ThumbnailCover from "components/ThumbnailCover";
import ThumbnailPoster from "components/ThumbnailPoster";
import ThumbnailSquare from "components/ThumbnailSquare";
import consts from "consts/consts";
import responsiveConf from "consts/responsive";
import { getFromLocal } from "helpers/localStorage";
import React, { Component, Fragment } from "react";
import { Trans, withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import { compose } from "redux";
import { ASSET_TYPE, getImageType } from "web-api/consts/tucano";
import { TucanoActions, TucanoModels, TucanoSelectors } from "web-api/main";

class PageVOD extends Component {
  state = {
    items: [],
    range: [0, 3],
    hasMore: true,
    componentReady: false,
    currentSlideIndex: 0,
    slides: [],
  };

  shouldComponentUpdate(nextProps) {
    return (
      this.props.strates === nextProps.strates ||
      (this.props.slides?.length === 0 && nextProps.slides?.length > 0)
    );
  }
  componentDidUpdate(prevProps) {
    if (prevProps.catalog !== this.props.catalog) {
      this.fetchMoreData();
    }
    if (prevProps.slides?.length === 0 && this.props.slides?.length > 0) {
      this.setState({
        slides: this.props.slides,
      });
    }
  }
  async componentDidMount() {
    const { dispatch, catalog, isConnected, t, route } = this.props;

    await this.setState({ items: [] });
    if (consts.appModules.vod !== true) {
      this.props.history.replace("/");
    }
    if (isConnected) {
      // i comment the following line is redundant and it cause duplicate rails after page refresh
      // await dispatch(TucanoActions.getListOptions(consts.languageId));
      await dispatch(
        TucanoActions.getActiveAssets({
          languageId: getFromLocal("languageId"),
          allAssets: consts?.continueWatching?.allAssets || 0,
          count: consts?.continueWatching?.count || 1000,
          watchingMin:
            consts?.continueWatching?.watchingMin >= 0 &&
            consts?.continueWatching?.watchingMin <= 100
              ? consts?.continueWatching?.watchingMin
              : 1,
          watchingMax:
            consts?.continueWatching?.watchingMax >= 0 &&
            consts?.continueWatching?.watchingMax <= 100
              ? consts?.continueWatching?.watchingMax
              : 95,
          contentTypeFilter:
            consts?.continueWatching?.contentTypeFilter || "movie+series",
        })
      );
    }

    if (catalog) await this.fetchMoreData();
    document.title = `${t(route.title)} - ${consts.name}`;
    if (consts.appTitle) {
      document.title = `${consts.appTitle} - ${t(route.title)}`;
    }
    if (consts.vodMetaData.length > 0) {
      document.getElementsByTagName("meta")[3].content =
        consts.vodMetaData[0].content;
      document.getElementsByTagName("meta")[4].content =
        consts.vodMetaData[1].content;
      document.getElementsByTagName("meta")[5].content =
        consts.vodMetaData[2].content;
    }
    await this.setState({ componentReady: true });
  }

  componentWillUnmount() {
    this.props.dispatch(TucanoActions.resetVOD());
  }

  subscriptionModal(asset) {
    if (this.props.subscriptionModal) {
      this.props.subscriptionModal(asset);
    }
  }

  fetchMoreData = async (loadingMore) => {
    const { catalog } = this.props;
    if (catalog && catalog.categories) {
      let range = this.state.range;
      let items = Array.from(this.state.items);
      if (range[1] >= catalog.categories.length) {
        range[1] = catalog.categories.length;
        await this.setState({ hasMore: false });
      }
      const languageId = getFromLocal("languageId");
      await this.props
        .dispatch(
          TucanoActions.getVODContent(
            catalog,
            languageId,
            this.state.range,
            loadingMore
          )
        )
        .then(async () => {
          items.push.apply(items, this.props.strates);
        });

      range[0] = range[1];
      range[1] =
        range[1] + 5 <= catalog.categories.length
          ? range[1] + 5
          : catalog.categories.length;
      this.setState({ range: range, items: items });
    }
  };

  updateCurrentIndex = (index) => {
    this.setState({ currentSlideIndex: index });
  };

  render() {
    const { isLoading, activeAssetsStrates, viewport, activeProfile, t } =
      this.props;
    const { currentSlideIndex, items, componentReady, hasMore, slides } =
      this.state;
    // clone & delete first element
    const cloneItemsSlice = consts.showVODFullscreen
      ? [...items]?.slice(1)
      : items;
    if (activeAssetsStrates) {
      activeAssetsStrates.setTitle("Continue watching");
    }
    const thumbnailSize = responsiveConf.find((item) => {
      return item.name === viewport.type;
    });

    return (
      <Fragment>
        {isLoading && <Loader centered={true} />}
        {!isLoading && items.length > 0 && (
          <Fragment>
            {slides && slides.length > 0 && consts.showVODFullscreen && (
              <SlideShow
                pagination={slides.length > 1 ? true : false}
                interval={consts.carousel.interval}
                autoplay={consts.carousel.autoplay}
                onChangeIndex={this.updateCurrentIndex.bind(this)}
              >
                {slides.map((slide, index) => (
                  <Slide
                    key={index}
                    asset={slide}
                    isPlayable={true}
                    subscriptionModal={this.subscriptionModal.bind(this)}
                    index={index}
                    currentSlideIndex={currentSlideIndex}
                    showTrailer="vodSlides"
                    showTopGradient={true}
                    profile={activeProfile}
                  />
                ))}
              </SlideShow>
            )}
            <ContentWrapper>
              {!consts.showVODFullscreen && (
                <Header>
                  <Trans>{this.props.route.title}</Trans>
                </Header>
              )}
              {activeAssetsStrates &&
                activeAssetsStrates?.getItems()?.length > 0 && (
                  <Strate
                    item={activeAssetsStrates}
                    baseUrl={consts.routes.history.url}
                    offsetTop={-45}
                  >
                    {activeAssetsStrates.getItems().map((item, item_index) => {
                      return (
                        <ThumbnailContinueWatching
                          usePosterImage={true}
                          key={item_index}
                          asset={item}
                          thumbnailSize={thumbnailSize}
                          onlyFavIcon={true}
                          onlyPlay={false}
                          showOnlyLogo={true}
                          subscriptionModal={this.subscriptionModal.bind(this)}
                          style={{
                            maxWidth: thumbnailSize.cover.width,
                            minWidth: thumbnailSize.cover.width,
                          }}
                          profile={activeProfile}
                        />
                      );
                    })}
                  </Strate>
                )}
              <InfiniteScroll
                // TODO fix bug of this componenet or try another package (method loadmor get executed before componentdidmount)
                // dataLength={this.state.items.length}
                pageStart={0}
                // next={this.fetchMoreData}
                loadMore={() => {
                  if (componentReady) {
                    this.fetchMoreData(true);
                  }
                }}
                hasMore={hasMore}
                loader={<Loader centered={false} key={0} />}
                threshold={100}
                // useWindow={true}
                // endMessage={
                //   <p style={{ textAlign: "center" }}>
                //     <b>Yay! You have seen it all</b>
                //   </p>
                // }
              >
                {cloneItemsSlice?.map((strate, index) => (
                  <Strate
                    key={index}
                    item={strate}
                    baseUrl={consts.routes.vod.url}
                  >
                    {strate.getItems().map((item, item_index) => {
                      // Multiple itemId exists for Assets
                      if (item.constructor === TucanoModels.Channel) {
                        return (
                          <ThumbnailSquare
                            key={item_index}
                            channel={item}
                            size={thumbnailSize}
                            linkToPlayer={false}
                            profile={activeProfile}
                          />
                        );
                      }
                      if (consts.designMode === 169) {
                        return (
                          <ThumbnailCover
                            activeTrailer={true}
                            key={item_index}
                            asset={item}
                            imageType={
                              item.isAssetTVShow
                                ? getImageType(
                                    consts.designMode,
                                    ASSET_TYPE.VOD_CATEGORY
                                  )
                                : getImageType(
                                    consts.designMode,
                                    item.isCategory
                                      ? ASSET_TYPE.VOD_CATEGORY
                                      : ASSET_TYPE.VOD
                                  )
                            }
                            size={thumbnailSize}
                            subscriptionModal={this.subscriptionModal.bind(
                              this
                            )}
                            profile={activeProfile}
                          />
                        );
                      }
                      return (
                        <ThumbnailPoster
                          activeTrailer={true}
                          isSlider={true}
                          order={item_index + 1}
                          key={item_index}
                          asset={item}
                          size={thumbnailSize}
                          subscriptionModal={this.subscriptionModal.bind(this)}
                          profile={activeProfile}
                        />
                      );
                    })}
                  </Strate>
                ))}
              </InfiniteScroll>
            </ContentWrapper>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default compose(
  connect((state) => {
    return {
      isLoading: state.content.vod.loading,
      viewport: state.ui.viewport,
      catalog: TucanoSelectors.getCatalog(state, 2),
      slides: TucanoSelectors.getVODSlideshow(state) || [],
      strates: TucanoSelectors.getVODStrates(state, true),
      state: state,
      activeAssetsStrates: TucanoSelectors.getVODActiveAssetsStrate(state),
      activeProfile: TucanoSelectors.getActiveProfile(state),
    };
  }),
  withTranslation()
)(PageVOD);
