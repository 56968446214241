import ContentWrapper from "components/ContentWrapper";
import Header from "components/Header";
import LinkBack from "components/LinkBack";
import Loader from "components/Loader";
import Slide from "components/Slide";
import SlideShow from "components/SlideShow";
import Strate from "components/Strate";
import ThumbnailCover from "components/ThumbnailCover";
import consts from "consts/consts";
import responsiveConf from "consts/responsive";
import { getFromLocal } from "helpers/localStorage";
import React, { Component, Fragment } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { TucanoActions, TucanoConsts, TucanoSelectors } from "web-api/main";

import style from "./style.module.css";

class PageCatchUpChannel extends Component {
  fetch() {
    this.props.dispatch(
      TucanoActions.getChannels(
        undefined,
        undefined,
        getFromLocal("languageId")
      )
    );
    const { t } = this.props;
    let channel = this.props.channel;
    document.title = `${t(this.props.route.title)} - ${consts.name}`;
    if (consts.appTitle) {
      document.title = `${consts.appTitle} - ${t(this.props.route.title)}`;
    }
    if (consts.catchupMetaData.length > 0) {
      document.getElementsByTagName("meta")[3].content =
        consts.catchupMetaData[0].content;
      document.getElementsByTagName("meta")[4].content =
        consts.catchupMetaData[1].content;
      document.getElementsByTagName("meta")[5].content =
        consts.catchupMetaData[2].content;
    }
    // PageCatchUpChannel.fetch(this.props.dispatch, true, channel);
    const languageId = getFromLocal("languageId");
    this.props.dispatch(TucanoActions.getCatchupDetail(channel, languageId));
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.items == undefined &&
      prevProps.items !== this.props.items &&
      this.props.items &&
      this.props.channel
    ) {
      this.fetch();
    }
  }
  componentDidMount() {
    if (consts.appModules && consts.appModules.catchup !== true) {
      this.props.history.push("/");
    }
    if (this.props.items && this.props.channel) {
      this.fetch();
    }
  }

  subscriptionModal(asset) {
    if (this.props.subscriptionModal) {
      this.props.subscriptionModal(asset);
    }
  }

  render() {
    const { isLoading, slides, strates, viewport, activeProfile } = this.props;
    const thumbnailSize = responsiveConf.find((item) => {
      return item.name === viewport.type;
    });
    const direction = getFromLocal("layoutDirection");
    return (
      <Fragment>
        {isLoading && <Loader centered={true} />}
        {!isLoading && (
          <Fragment>
            <LinkBack
              rootClassName={
                slides && consts.showCatchupFullscreen
                  ? direction.toLocaleLowerCase() === "rtl"
                    ? style.linkBackRTL
                    : style.linkBack
                  : direction.toLocaleLowerCase() === "rtl"
                  ? style.linkBackRTL
                  : style.linkBackWithoutSlide
              }
            />
            {slides && !!consts.showCatchupFullscreen && (
              <>
                <SlideShow
                  pagination={slides.length > 1 ? true : false}
                  autoplay={slides.length > 1 ? true : false}
                  interval={5000}
                >
                  {slides &&
                    slides.map((item, index) => (
                      <Slide
                        key={index}
                        asset={item}
                        isPlayable={true}
                        subscriptionModal={this.subscriptionModal.bind(this)}
                        showTopGradient={true}
                        profile={activeProfile}
                      />
                    ))}
                </SlideShow>
              </>
            )}
            {strates && (
              <>
                <ContentWrapper
                  rootClassName={
                    slides ? undefined : style.pageCatchupContentWrapper
                  }
                >
                  {!consts.showCatchupFullscreen && (
                    <Header rootClassName={style.headerTitle}>
                      <Trans>Catch-up</Trans>: {this.props.channel.name}
                    </Header>
                  )}
                  {strates.map((item, index) => (
                    <Strate
                      key={index}
                      item={item}
                      baseUrl={window.location.pathname}
                    >
                      {item.getItems().map((asset, assetIndex) => {
                        return (
                          <ThumbnailCover
                            key={assetIndex}
                            asset={asset}
                            size={thumbnailSize}
                            subscriptionModal={this.subscriptionModal.bind(
                              this
                            )}
                            profile={activeProfile}
                          />
                        );
                      })}
                    </Strate>
                  ))}
                </ContentWrapper>
              </>
            )}
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default compose(
  connect((state, props) => {
    const channelSlug = props.match.params.slug;
    const catalog = TucanoSelectors.getCatalog(
      state,
      TucanoConsts.CatalogType.CATCHUP
    );
    const items =
      catalog && TucanoSelectors.getCatchupChannels(state, catalog.idCatalog);
    const channel =
      channelSlug && TucanoSelectors.getCategoryBySlug(state, channelSlug);
    return {
      isLoading: state.content.catchup.loading,
      items: items,
      channel: channel,
      slides: TucanoSelectors.getCatchupChannelSlideshow(state),
      strates: TucanoSelectors.getCatchupChannelStrates(state),
      viewport: state.ui.viewport,
      activeProfile: TucanoSelectors.getActiveProfile(state),
    };
  }),
  withTranslation()
)(PageCatchUpChannel);
