import ContentWrapper from "components/ContentWrapper";
import ErrorMessage from "components/ErrorMessage";
import Grid from "components/Grid";
import Header from "components/Header";
import Loader from "components/Loader";
import ThumbnailCover from "components/ThumbnailCover";
import consts from "consts/consts";
import responsiveConf from "consts/responsive";
import PageAbstract from "containers/PageAbstract";
import { getFromLocal } from "helpers/localStorage";
import React, { Fragment } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { TucanoActions, TucanoSelectors } from "web-api/main";

import style from "./style.module.css";

class PageHistory extends PageAbstract {
  static fetch(dispatch) {
    dispatch(
      TucanoActions.getActiveAssets({
        languageId: getFromLocal("languageId"),
        allAssets: consts?.continueWatching?.allAssets || 0,
        count: consts?.continueWatching?.count || 1000,
        watchingMin:
          consts?.continueWatching?.watchingMin >= 0 &&
          consts?.continueWatching?.watchingMin <= 100
            ? consts?.continueWatching?.watchingMin
            : 1,
        watchingMax:
          consts?.continueWatching?.watchingMax >= 0 &&
          consts?.continueWatching?.watchingMax <= 100
            ? consts?.continueWatching?.watchingMax
            : 95,
        contentTypeFilter:
          consts?.continueWatching?.contentTypeFilter || "movie+series",
      })
    );
  }

  componentDidMount() {
    const { t } = this.props;
    document.title = t(this.props.route.title);
    if (consts.appTitle) {
      document.title = `${consts.appTitle} - ${t(this.props.route.title)}`;
    }

    if (this.props.isConnected) {
      PageHistory.fetch(this.props.dispatch, this.props.isConnected);
    } else {
      this.props.history.push("?login=1");
    }
  }

  subscriptionModal(asset) {
    if (this.props.subscriptionModal) {
      this.props.subscriptionModal(asset);
    }
  }

  render() {
    const { isLoading, activeAssets, viewport, t, isConnected, activeProfile } =
      this.props;
    const thumbnailSize = responsiveConf.find((item) => {
      return item.name === viewport.type;
    });
    return (
      <Fragment>
        {isLoading && <Loader centered={true} />}
        {!isLoading && (
          <ContentWrapper>
            <div className={style.root}>
              <Header>
                <Trans>{this.props.route.title}</Trans>
              </Header>
              {activeAssets && activeAssets.length > 0 ? (
                <div className={style.activeAssetsContainer}>
                  <Grid rootClassName={style.grid}>
                    {activeAssets &&
                      activeAssets.map((activeAsset, index) => {
                        return (
                          <ThumbnailCover
                            key={index}
                            usePosterImage={true}
                            asset={activeAsset}
                            activeTrailer={true}
                            isConnected={isConnected}
                            size={thumbnailSize}
                            subscriptionModal={this.subscriptionModal.bind(
                              this
                            )}
                            showMoralityLevel={true}
                            profile={activeProfile}
                          />
                        );
                      })}
                  </Grid>
                </div>
              ) : (
                <ErrorMessage
                  rootClassName={style.errorMessage}
                  message={t(
                    "You’ll find the movies and series you started here"
                  )}
                />
              )}
            </div>
          </ContentWrapper>
        )}
      </Fragment>
    );
  }
}

export default compose(
  connect((state) => {
    return {
      isLoading: state.content.activeAssets.loading,
      isConnected: state.session.customerAuthToken !== undefined,
      viewport: state.ui.viewport,
      activeAssets: TucanoSelectors.getActiveAssets(state),
      activeProfile: TucanoSelectors.getActiveProfile(state),
    };
  }),
  withTranslation()
)(PageHistory);
