import InlineSVG from "inline-svg-react";
import React from "react";

const SkipAd = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/SkipAd.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/SkipAd.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 120 135" {...props}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.2518 67.4981L0.00337793 0H29.7016L85.95 67.4981L29.6982 135H0L56.2518 67.4981Z"
          />
          <rect x="97.5" width="22.4998" height="134.999" />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};

export default SkipAd;
