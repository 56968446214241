import classnames from "classnames";
import AutoClassification from "components/AutoClassificationImg";
import PlayIcon from "components/Icons/Play";
import Image, { ImageRatio, PlaceholderType } from "components/Image";
import LogoVod from "components/LogoVod";
import ProgressBar from "components/ProgressBar";
import ThumbnailInfos from "components/ThumbnailInfos";
import consts from "consts/consts";
import { getFromLocal } from "helpers/localStorage";
import { applyBlur } from "helpers/utility";
import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import { TucanoModels } from "web-api/main";

import routes from "../../web-api/consts/routes";
import style from "./style.module.css";

class Episode extends Component {
  state = {
    hover_id: null,
    hover: false,
  };

  subscriptionModal(asset) {
    if (this.props.subscriptionModal) {
      this.props.subscriptionModal(asset);
    }
  }
  render() {
    const { season, isPhoneSize, profile } = this.props;
    const width = isPhoneSize ? 340 : 270;
    const height = isPhoneSize ? 191 : 151;

    return (
      <div className={style.root}>
        {season &&
          season.getAssets() &&
          season.getAssets().map((asset, index) => (
            <div
              key={index + " - " + asset.title}
              className={style.assetContainer}
            >
              <div
                className={style.leftContainer}
                onMouseEnter={() =>
                  this.setState({ hover_id: index, hover: true })
                }
                onMouseLeave={() =>
                  this.setState({ hover_id: index, hover: false })
                }
              >
                <Image
                  src={`${consts.image_hostname}${routes.imgdata}?type=${
                    consts.imageTypes.movie_episode_cover
                  }&objectId=${asset.getId()}&format_w=${764}&format_h=${430}&languageId=${getFromLocal(
                    "languageId"
                  )}`}
                  height={height}
                  width={width}
                  ratio={ImageRatio.COVER}
                  placeholder={PlaceholderType.PRIMARY}
                  blur={applyBlur(asset, profile)}
                />
                {consts.displayVodData &&
                  asset &&
                  asset instanceof TucanoModels.Asset &&
                  asset.flyImage && (
                    <div className={classnames(style.VODLogo)}>
                      <LogoVod
                        className={classnames(style.vodData)}
                        type={asset.flyImage}
                        size={40}
                      />
                    </div>
                  )}
                {this.props.flyImage && (
                  <div className={classnames(style.VODLogo)}>
                    <LogoVod
                      className={classnames(style.vodData)}
                      type={this.props.flyImage}
                      size={40}
                    />
                  </div>
                )}

                {isPhoneSize && <PlayIcon className={style.playIcon} />}
                {!!asset.getSeekTime() && (
                  <ProgressBar
                    position={asset.getProgress()}
                    rootClassName={style.progressBar}
                  />
                )}
                <ThumbnailInfos
                  show={this.state.hover && this.state.hover_id === index}
                  model={asset}
                  onlyPlay={true}
                  displayFavoriteToggle={false}
                  subscriptionModal={this.subscriptionModal.bind(this)}
                />
              </div>
              <div className={style.rightContainer}>
                <div className={style.seasonTitle}>
                  <Trans>Season</Trans> {asset.getSeasonNumber()} -{" "}
                  <Trans>Episode</Trans> {asset.getEpisodeNumber()}
                  <div className={style.durationEP}>
                    ( {asset.getDuration()}MIN{" "}
                    {!asset.getAutoClassification &&
                      asset.getMoralityLevel() &&
                      `| ${asset.getMoralityLevel()}+ `}
                    )
                    {!!asset.getAutoClassification && (
                      <AutoClassification
                        morality={asset.getAutoClassification}
                      />
                    )}
                  </div>
                </div>
                <div className={style.episodeTitle}>{asset.getTitle()}</div>
                <div className={style.episodeSynopsis}>
                  {asset.getSynopsis()}
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  }
}

export default withTranslation()(Episode);
