import consts from "consts/consts";
import { slugify } from "web-api/helpers/urlHelper";

import * as tucanoConsts from "../consts/tucano";
import { generateImageUrl, generatePosterImageUrl } from "../helpers/imageHelper";
import { formatFullDate, formatMinutesInHumanDuration } from "../helpers/timeHelper";
import Strate from "./Strate";

export const LAYOUT_LANDSCAPE = "LAYOUT_LANDSCAPE";
export const LAYOUT_PORTRAIT = "LAYOUT_PORTRAIT";
export const LAYOUT_ACTIVE_ASSETS = "LAYOUT_ACTIVE_ASSETS";

export const AssetDesign = {
  FULLSCREEN: "fullscreen",
  SQUARE: "square",
  SQUARE_FULL: "square_full",
  POSTER: "poster",
  POSTER_LARGE: "poster_large",
  COVER: "cover",
  COVER_FULL: "cover_full",
  CIRCLE: "circle",
  TOTEM: "totem",
  CONTINUE_WATCHING: "continue_watching",
  BANNER: "banner",
};

export default class Asset {
  constructor(asset, layout = LAYOUT_PORTRAIT, assetType) {
    // console.log("Asset input", asset);
    this.program = null;
    this.record = null;
    this.assetType = asset?.type || asset?.content?.type;
    this.layout = layout;
    this.baseId = asset.baseId;
    this.content = asset.content;
    this.type =
      asset.itemType ||
      (asset.assetType === "category"
        ? tucanoConsts.ImageType.VOD_CATEGORY_THUMBNAIL
        : tucanoConsts.ImageType.VOD_THUMBNAIL);
    this.tags = [];
    this.flyImage = undefined;
    if (asset.tags) {
      this.tags = asset.tags;
    }
    if (asset.tags && asset.tags.imageType) {
      // Until Tucano is migrated to >2019.4.7
      // Hack because imageType include twice "asset_"
      this.type = asset.tags.imageType.replace("asset_asset_", "asset_");
      if (asset.tags.designMode[0]) {
        this.designMode = asset.tags.designMode[0];
      } else {
        this.designMode = AssetDesign.POSTER;
      }
    }

    let item = asset;
    // Used for homecontent
    if (asset.content && asset.idType !== 7) {
      item = asset.content;
    }
    this.id = item.idAsset || item.idCatalog || item.idChannel || item.idItem;
    this.name = item.name;
    this.isAssetTVShow = false;
    this.isCategory =
      asset.itemType === 8 ||
      asset.itemType === tucanoConsts.ImageType.VOD_CATEGORY_THUMBNAIL ||
      asset.idType === 8 ||
      asset.assetType === "category" ||
      assetType === "category";
    this.isSerie = item.serie === true;

    if (item.serie) {
      this.isAssetTVShow = true;
      this.parentId = this.id;
    }
    this.cast = [];
    this.directors = [];

    if (asset.idType) {
      this.idType = asset.idType;
    }
    if (item.title) {
      this.title = item.title;
    }
    if (item.synopsis) {
      this.synopsis = item.synopsis;
    }
    if (typeof item.directMetadata === "object") {
      // For TVShowEpisode
      if (item.directMetadata.AD_SERIESEA || item.directMetadata.AD_ISSERIES) {
        const pathSplit = item.path[0].split("/");
        this.parentId = parseInt(pathSplit.splice(pathSplit.length - 2, 1));
        //console.log("parentId => ", this.parentId);
      }
      if (item.directMetadata.AD_LIFLIES && item.directMetadata.AD_LIFLIES.length > 0) {
        this.flyImage = item.directMetadata.AD_LIFLIES[0];
      }
      if (item.directMetadata.AD_SURTITLE || item.directMetadata.trans_title) {
        this.parentTitle = item.directMetadata.trans_title || item.directMetadata.AD_SURTITLE;
      }
      if (item.directMetadata.TS_STARTUTC) {
        this.startTime = item.directMetadata.TS_STARTUTC;
      }
      if (item.directMetadata.TS_ENDUTC) {
        this.endTime = item.directMetadata.TS_ENDUTC;
      }
      if (item.directMetadata.AD_VODMOD) {
        this.vodData = item.directMetadata.AD_VODMOD;
      }
      if (item.directMetadata.AD_ASSTITLE) {
        this.title = item.directMetadata.AD_ASSTITLE;
      }
      if (item.directMetadata.AD_CSASNAME) {
        this.moralityLevel = item.directMetadata.AD_CSASNAME;
      }
      if (item.directMetadata.TS_DURATION) {
        this.duration = item.directMetadata.TS_DURATION;
      }
      if (item.directMetadata.TS_RELEDATE) {
        this.releaseYear = item.directMetadata.TS_RELEDATE;
      }
      if (item.directMetadata.AD_SYNO600C) {
        this.synopsis = item.directMetadata.AD_SYNO600C;
      }
      if (item.directMetadata.AD_LIGENRES) {
        this.genre = item.directMetadata.AD_LIGENRES;
      }
      if (item.directMetadata.AD_LIACTORS) {
        this.cast = item.directMetadata.AD_LIACTORS;
      }
      if (item.directMetadata.AD_LIREALIS) {
        this.directors = item.directMetadata.AD_LIREALIS;
      }
      if (typeof item.directMetadata.AD_SERIESEA !== "undefined") {
        this.seasonNumber = item.directMetadata.AD_SERIESEA;
      }
      if (typeof item.directMetadata.AD_SERIESEP !== "undefined") {
        this.episodeNumber = item.directMetadata.AD_SERIESEP;
      }
      if (item.directMetadata.CA_LICHANID) {
        this.providerId = item.directMetadata.CA_LICHANID[0];
      }
      if (
        item.directMetadata.AD_LITRAILE &&
        item.directMetadata.AD_LITRAILE[0] &&
        item.directMetadata.AD_LITRAILE[0].idMedia
      ) {
        this.trailerId = item.directMetadata.AD_LITRAILE[0].idMedia;
      }
      if (item.directMetadata.SA_CATCHUP) {
        this.catchupId = item.directMetadata.SA_CATCHUP;
      }
      if (item.directMetadata.AD_TCMPRICE) {
        this.price = item.directMetadata.AD_TCMPRICE;
      }
      if (item.directMetadata.AD_LIASMEDI) {
        this.lang = item.directMetadata.AD_LIASMEDI;
      }
      if (item.directMetadata?.["tv.alphanetworks.tucano.autoClassification"]) {
        this.autoClassification = item.directMetadata?.["tv.alphanetworks.tucano.autoClassification"];
      }
    }
    if (item.rating) {
      this.score = item.rating.score;
      this.averageScore = item.rating.average;
    }
    if (item.seekTime) {
      this.seekTime = item.seekTime;
    }
    if (item.validityStartDate) {
      this.validityStartDate = item.validityStartDate;
    }
    if (item.validityEndDate) {
      this.validityEndDate = item.validityEndDate;
    }
    if (item.name) {
      this.title = item.name;
    }

    if (item.synopsis) {
      this.synopsis = item.synopsis;
    }
    if (item.categories) {
      this.seasonCount = item.categories.length;
    }
    if (item.content && item.content.url) {
      this.url = item.content.url;
    }

    if (item.absolute_asset_number) {
      this.absoluteAssetNumber = item.absolute_asset_number;
    }

    this.catalogs = item.catalog !== undefined ? item.catalog : [];

    this.packageIds = item.packageIds !== undefined ? item.packageIds : [];

    this.menuOrder = [];
    this.listOptions = item.listOptions || [];
    this.optionsValidity = item.optionsValidity || [];
    this.activeAssetsTVOD = item.listOptions || [];

    this.isAssetTVShowEpisode = this.episodeNumber !== undefined;

    this.liveEvent = item.live;

    this.adult = item.adult;

    // Recommendation
    this.monolistRecommendation = [];
    if (asset.monolistRecommendation !== undefined && asset.monolistRecommendation.assets !== undefined) {
      this.monolistRecommendation = asset.monolistRecommendation.assets.map((item) => {
        item.itemType = tucanoConsts.ImageType.VOD_THUMBNAIL;
        item.listOptions = this.listOptions;
        item.activeAssetsTVOD = this.activeAssetsTVOD;

        return new Asset(item);
      });
    }

    this.multifacetRecommendation = [];
    if (asset.multifacetRecommendation !== undefined && asset.multifacetRecommendation.rails !== undefined) {
      this.multifacetRecommendation = asset.multifacetRecommendation.rails.map((item) => {
        const strate = new Strate(item);

        strate.setItems(
          item.assets.map((asset) => {
            if (asset.constructor === Asset) {
              return asset;
            }

            asset.itemType = tucanoConsts.ImageType.VOD_THUMBNAIL;
            asset.listOptions = this.listOptions;
            asset.activeAssetsTVOD = this.activeAssetsTVOD;

            return new Asset(asset);
          })
        );

        return strate;
      });
    }
  }

  setRecord(record) {
    this.record = record;
  }

  getRecord() {
    return this.record;
  }

  getRecordStatus() {
    return this.getRecord() ? this.getRecord().getStatus() : "Record";
  }

  setProgram(program) {
    this.program = program;
  }

  getProgram() {
    return this.program;
  }

  getIdType() {
    return this.idType;
  }
  getId() {
    return this.id;
  }
  getStartTime() {
    return this.startTime;
  }
  getEndTime() {
    return this.endTime;
  }
  getName() {
    return this.name;
  }

  getSlug() {
    return slugify(this.name);
  }

  getParentId() {
    return this.parentId;
  }

  getTagsArray() {
    return this.tags;
  }

  isTVShow() {
    return this.isAssetTVShow;
  }

  isTVShowEpisode() {
    return this.isAssetTVShowEpisode;
  }

  getType() {
    return this.type;
  }

  getTitle() {
    return this.title;
  }

  getParentTitle() {
    return this.parentTitle;
  }
  getVodData() {
    return this.vodData;
  }

  getMoralityLevel() {
    return this.moralityLevel;
  }

  get getAutoClassification() {
    return this.autoClassification;
  }

  getImage(domain = "/", options) {
    return `${domain}${generateImageUrl(this, options)}`;
  }

  getPosterImage(domain = "/", options) {
    return `${domain}${generatePosterImageUrl(this, options)}`;
  }

  getGenre() {
    if (this.genre) {
      return this.genre.join(", ");
    }
  }

  getReleaseYear() {
    return this.releaseYear;
  }

  getScore() {
    return this.score;
  }

  getAverageScore() {
    let result = undefined;
    if (!this.averageScore) {
      result = 0;
    }
    if (Number.isInteger(this.averageScore)) {
      result = this.averageScore;
    }
    if (!result && result !== 0) {
      result = parseFloat(this.averageScore).toFixed(2);
    }
    if (result > 5) {
      result = 5;
    }
    return result;
  }

  getSeasonNumber() {
    return this.seasonNumber;
  }

  getEpisodeNumber() {
    return this.episodeNumber;
  }

  getSynopsis() {
    return this.synopsis;
  }

  getCast() {
    return this.cast;
  }
  getContent() {
    return this.content;
  }

  getCastFirstRoles() {
    if (this.cast) {
      return this.cast.slice(0, consts.mainCastMax);
    }
  }

  getCastSecondRoles() {
    if (this.cast) {
      return this.cast.slice(consts.mainCastMax, consts.mainCastMax + consts.subCastMax).join(", ");
    }
  }

  getDirectors() {
    if (this.directors) {
      return this.directors.join(", ");
    }
  }

  getHumanDuration() {
    return formatMinutesInHumanDuration(this.duration);
  }

  getTrailerId() {
    return this.trailerId;
  }

  getLayout() {
    return this.layout;
  }

  getPrice() {
    return this.price;
  }

  getCatchupId() {
    return this.catchupId;
  }

  isCatchup() {
    return this.isCatchupAsset;
  }

  getDuration() {
    return this.duration;
  }

  getSeekTime() {
    return this.seekTime;
  }

  getValidityStartDate() {
    if (!this.validityStartDate) return "";
    const startDate = formatFullDate(new Date(this.validityStartDate * 1000));
    return startDate;
  }

  getValidityEndDate() {
    return this.validityEndDate * 1000;
  }

  setSeekTime(value) {
    this.seekTime = value;
  }

  getProgress() {
    const progress = (this.getSeekTime() / (this.getDuration() * 60)) * 100;
    return progress;
  }

  getProviderId() {
    return this.providerId;
  }

  getSeasonCount() {
    return this.seasonCount;
  }

  getMonolistRecommendation() {
    return this.monolistRecommendation;
  }

  getMultifacetRecommendation() {
    return this.multifacetRecommendation;
  }

  getUrl() {
    return this.url;
  }

  getAbsoluteAssetNumber() {
    return this.absoluteAssetNumber;
  }

  setMenuOrder(menuOrder) {
    this.menuOrder = menuOrder;
  }

  setListOptions(listOptions) {
    this.listOptions = listOptions;
  }

  setOptionsValidity(options) {
    this.optionsValidity = options;
  }

  setActiveAssetTVOD(activeAssetsTVOD) {
    this.activeAssetsTVOD = activeAssetsTVOD;
  }

  get isCatchupAsset() {
    const catchUpCatalog = this.menuOrder?.find((catalog) => {
      return catalog.idType === tucanoConsts.CatalogType.CATCHUP;
    });

    if (catchUpCatalog === undefined) {
      return false;
    }

    return this.catalogs.some((catalogId) => {
      return catalogId === Math.abs(catchUpCatalog.id);
    });
  }

  get userHasAccess() {
    let packages = [];
    let validStatus = {
      active: 10,
      terminated: 40,
    };
    if (this.listOptions && this.listOptions.length > 0) {
      this.listOptions.map((option) => {
        let validity = this.optionsValidity?.find((validity) => {
          return option.idOption === validity.optionId;
        });

        if (
          [validStatus.active, validStatus.terminated].includes(validity?.statusId) &&
          option.validFrom !== null &&
          (option.validTo * 1000 > new Date().getTime() || option.validTo === null)
        ) {
          if (option && option.objectIds && option.objectIds.package) {
            packages = packages.concat(option.objectIds.package);
          }
        }
        return null;
      });
    }

    let hasPackages = packages.some((v) => this.packageIds.includes(v));
    let hasAsset = false;
    if (hasPackages === false) {
      if (this.activeAssetsTVOD && this.activeAssetsTVOD.length > 0) {
        this.activeAssetsTVOD.map((active) => {
          if (active.idAsset === this.id) {
            hasAsset = true;
            return true;
          }
          return false;
        });
      }
    }
    return hasPackages || hasAsset || this.isAllowed;
  }

  getDesignMode() {
    return this.designMode;
  }

  setIsAllowed(isAllowed) {
    this.isAllowed = isAllowed;
  }

  get isLiveEvent() {
    return this.liveEvent;
  }

  get isAdult() {
    return this.adult;
  }
}
