import classnames from "classnames";
import ContentWrapper from "components/ContentWrapper";
import Grid from "components/Grid";
import Header from "components/Header";
import LinkBack from "components/LinkBack";
import Loader from "components/Loader";
import ThumbnailCover from "components/ThumbnailCover";
import consts from "consts/consts";
import responsiveConf from "consts/responsive";
import { isToday, isYesterday } from "date-fns";
import { getFromLocal } from "helpers/localStorage";
import { CapitalizeFirstLetter } from "helpers/utility";
import moment from "moment";
import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { TucanoSelectors } from "web-api/main";

import style from "./style.module.css";

class PageCatchUpDetail extends Component {
  componentDidMount() {
    if (consts.appModules && consts.appModules.catchup !== true) {
      this.props.history.push("/");
    }
  }
  subscriptionModal(asset) {
    if (this.props.subscriptionModal) {
      this.props.subscriptionModal(asset);
    }
  }

  render() {
    const { isLoading, strate, viewport, t, i18n, activeProfile } = this.props;
    const thumbnailSize = responsiveConf.find((item) => {
      return item.name === viewport.type;
    });
    const direction = getFromLocal("layoutDirection");
    let title = "";
    if (/\/.*\//.test(strate?.title)) {
      const date = strate.title.split("/");
      if (
        isToday(
          new Date(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2]))
        )
      ) {
        title = t("Today");
      } else if (
        isYesterday(
          new Date(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2]))
        )
      ) {
        title = t("Yesterday");
      } else {
        title = CapitalizeFirstLetter(
          `${moment(
            new Date(
              parseInt(date[0]),
              parseInt(date[1]) - 1,
              parseInt(date[2])
            )
          )
            .locale(i18n.language)
            .format(consts.catchupDateFormat)
            .toLowerCase()}`
        );
      }
    } else {
      title = strate?.title;
    }
    return (
      <Fragment>
        {isLoading && <Loader centered={true} />}
        {!isLoading && (
          <Fragment>
            <ContentWrapper>
              <LinkBack
                rootClassName={classnames(
                  direction.toLocaleLowerCase() === "rtl"
                    ? style.linkBackRTL
                    : style.linkBack
                )}
              />
              <Header rootClassName={style.headerTitle}>{title}</Header>
              {strate?.items && strate?.items.length > 0 && (
                <Grid rootClassName={style.grid}>
                  {strate?.items.map((item, index) => {
                    return (
                      <ThumbnailCover
                        key={index}
                        asset={item}
                        size={thumbnailSize}
                        subscriptionModal={this.subscriptionModal.bind(this)}
                        profile={activeProfile}
                      />
                    );
                  })}
                </Grid>
              )}
            </ContentWrapper>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default compose(
  connect((state, props) => {
    const channelSlug = props.match.params.slug;
    const date = new Date(props.match.params.date);
    const paramId = props.match.params.date;
    return {
      isLoading: state.content.catchup.loading,
      viewport: state.ui.viewport,
      channel: TucanoSelectors.getCategoryBySlug(state, channelSlug),
      categoryId: TucanoSelectors.getCategoryBySlugAndDate(
        state,
        channelSlug,
        date
      ),
      strate: TucanoSelectors.getCatchupDetail(state, paramId),
      activeProfile: TucanoSelectors.getActiveProfile(state),
    };
  }),
  withTranslation()
)(PageCatchUpDetail);
