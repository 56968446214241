import actionTypes from "../consts/actionTypes";

const initialState = {
  globalLoader: {
    loading: false,
  },
  login: {
    loading: false,
    data: undefined,
  },
  ssoLogin: {
    authUser: {
      facebook: null,
      google: null,
      apple: null,
    },
    loading: false,
    data: undefined,
  },
  askResetPassword: {
    loading: false,
    data: undefined,
  },
  getPasswordPolicy: {
    loading: false,
    data: {},
    error: undefined,
  },
  resetPassword: {
    loading: false,
    data: undefined,
  },
  user: {
    loading: false,
    data: undefined,
  },
  devices: {
    loading: false,
    data: undefined,
    count: undefined,
  },
  invoices: {
    loading: false,
    data: undefined,
  },
  updatePassword: {
    loading: false,
    data: undefined,
  },
  parentalCode: {
    loading: false,
    updating: false,
    data: {},
  },
  validateParentalCode: {
    loading: false,
    data: undefined,
  },
  purchaseCode: {
    loading: false,
    updating: false,
    data: {},
  },
  validatePurchaseCode: {
    loading: false,
    data: undefined,
  },
  validateAccount: {
    loading: false,
    data: undefined,
    error: undefined,
  },
  changeAddress: {
    loading: false,
    data: undefined,
  },
  changeDetails: {
    loading: false,
    data: undefined,
  },
  casRemoveDevice: {
    loading: false,
    data: undefined,
  },
  casAddDevice: {
    loading: false,
    data: undefined,
    meta: undefined,
  },
  profile: {
    loading: false,
    data: undefined,
  },
  avatars: {
    loading: false,
    data: undefined,
  },
  createProfile: {
    loading: false,
    data: undefined,
  },
  updateProfile: {
    loading: false,
    data: undefined,
  },
  deleteProfile: {
    loading: false,
    data: undefined,
  },
  getActiveProfile: {
    loading: true,
    data: undefined,
  },
  setActiveProfile: {
    loading: false,
    data: undefined,
  },
  onSignupSteps: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_GLOBAL_LOADER:
      return {
        ...state,
        globalLoader: {
          loading: action.payload,
        },
      };
    case actionTypes.APPLE_AUTH_PENDING:
      return {
        ...state,
        ssoLogin: {
          loading: true,
        },
      };
    case actionTypes.APPLE_AUTH_SUCCESS:
      return {
        ...state,
        ssoLogin: {
          loading: false,
          data: action.payload,
        },
      };
    case actionTypes.APPLE_AUTH_ERROR:
      return {
        ...state,
        ssoLogin: {
          loading: false,
          error: action.payload,
        },
      };
    case actionTypes.FACEBOOK_AUTH_PENDING:
      return {
        ...state,
        ssoLogin: {
          loading: true,
        },
      };
    case actionTypes.FACEBOOK_AUTH_SUCCESS:
      return {
        ...state,
        ssoLogin: {
          loading: false,
          data: action.payload,
        },
      };
    case actionTypes.FACEBOOK_AUTH_ERROR:
      return {
        ...state,
        ssoLogin: {
          loading: false,
          error: action.payload,
        },
      };
    case actionTypes.LOGIN_PENDING:
      return {
        ...state,
        login: {
          loading: true,
        },
      };
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        login: {
          data: action.payload,
        },
      };
    case actionTypes.LOGIN_ERROR:
      return {
        ...state,
        login: {
          error: action.payload,
        },
      };
    case actionTypes.GET_ACCOUNT_DETAILS_PENDING:
      return {
        ...state,
        user: {
          loading: true,
          data: state?.user?.data,
        },
      };
    case actionTypes.GET_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        user: {
          loading: false,
          data: action.payload,
        },
      };
    case actionTypes.GET_ACCOUNT_DETAILS_ERROR:
      return {
        ...state,
        user: {
          error: action.meta,
        },
      };
    case actionTypes.GET_DEVICES_PENDING:
      return {
        ...state,
        devices: {
          loading: true,
        },
      };
    case actionTypes.GET_DEVICES_SUCCESS:
      return {
        ...state,
        devices: {
          loading: false,
          data: action.payload.request_1.device,
          count: {
            maxNumberOfDevices: action.payload.request_0.maxNumberOfDevices,
            numberOfDevices: action.payload.request_0.numberOfDevices,
          },
        },
      };
    case actionTypes.GET_DEVICES_ERROR:
      return {
        ...state,
        devices: {
          error: action.meta,
        },
      };
    case actionTypes.GET_PARENTAL_CODE_PENDING:
      return {
        ...state,
        parentalCode: {
          loading: true,
        },
      };
    case actionTypes.GET_PARENTAL_CODE_SUCCESS:
      return {
        ...state,
        parentalCode: {
          loading: false,
          data: action.payload.adultCode,
        },
      };
    case actionTypes.GET_PARENTAL_CODE_ERROR:
      return {
        ...state,
        parentalCode: {
          loading: false,
          error: action.meta,
        },
      };
    case actionTypes.GET_PURCHASE_CODE_PENDING:
      return {
        ...state,
        purchaseCode: {
          loading: true,
        },
      };
    case actionTypes.GET_PURCHASE_CODE_SUCCESS:
      return {
        ...state,
        purchaseCode: {
          loading: false,
          data: action.payload.purchaseCode,
        },
      };
    case actionTypes.GET_PURCHASE_CODE_ERROR:
      return {
        ...state,
        purchaseCode: {
          loading: false,
          error: action.meta,
        },
      };
    case actionTypes.GET_PASSWORD_POLICY_PENDING:
      return {
        ...state,
        getPasswordPolicy: {
          loading: true,
        },
      };
    case actionTypes.GET_PASSWORD_POLICY_SUCCESS:
      return {
        ...state,
        getPasswordPolicy: {
          loading: false,
          data: action.payload,
        },
      };
    case actionTypes.GET_PASSWORD_POLICY_ERROR:
      return {
        ...state,
        getPasswordPolicy: {
          loading: false,
          error: action.payload,
        },
      };
    case actionTypes.ASK_RESET_PASSWORD_PENDING:
      return {
        ...state,
        askResetPassword: {
          loading: true,
        },
      };
    case actionTypes.ASK_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        askResetPassword: {
          loading: false,
          meta: action.meta,
          error: action.payload,
        },
      };
    case actionTypes.ASK_RESET_PASSWORD_ERROR:
      return {
        ...state,
        askResetPassword: {
          loading: false,
          meta: action.meta,
          error: action.payload,
        },
      };
    case actionTypes.ASK_RESET_PASSWORD_RESET:
      return {
        ...state,
        askResetPassword: {
          loading: false,
          meta: undefined,
          error: undefined,
        },
      };
    case actionTypes.RESET_PASSWORD_PENDING:
      return {
        ...state,
        resetPassword: {
          loading: true,
        },
      };
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: {
          loading: false,
          meta: action.meta,
          error: action.payload,
        },
      };
    case actionTypes.RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetPassword: {
          loading: false,
          meta: action.meta,
          error: action.payload,
        },
      };
    case actionTypes.RESET_PASSWORD_RESET:
      return {
        ...state,
        resetPassword: {
          loading: false,
          meta: undefined,
          error: undefined,
        },
      };
    case actionTypes.GET_INVOICES_PENDING:
      return {
        ...state,
        invoices: {
          loading: true,
          data: undefined,
        },
      };
    case actionTypes.GET_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: {
          loading: false,
          data: action.payload.invoice,
        },
      };
    case actionTypes.GET_INVOICES_ERROR:
      return {
        ...state,
        invoices: {
          error: action.payload,
        },
      };
    case actionTypes.UPDATE_PASSWORD_PENDING: {
      return {
        ...state,
        updatePassword: {
          loading: true,
        },
      };
    }
    case actionTypes.UPDATE_PASSWORD_SUCCESS: {
      return {
        ...state,
        updatePassword: {
          loading: false,
          meta: action.meta,
          data: action.payload,
        },
      };
    }
    case actionTypes.UPDATE_PASSWORD_ERROR: {
      return {
        ...state,
        updatePassword: {
          loading: false,
          meta: action.meta,
          data: action.payload,
        },
      };
    }
    case actionTypes.SET_ADULT_CODE_PENDING: {
      return {
        ...state,
        parentalCode: {
          ...state.parentalCode,
          updating: true,
        },
      };
    }
    case actionTypes.SET_ADULT_CODE_SUCCESS: {
      return {
        ...state,
        parentalCode: {
          ...state.parentalCode,
          updating: false,
        },
      };
    }
    case actionTypes.SET_ADULT_CODE_ERROR: {
      return {
        ...state,
        parentalCode: {
          ...state.parentalCode,
          updating: false,
        },
      };
    }
    case actionTypes.VALIDATE_ADULT_CODE_PENDING:
      return {
        ...state,
        validateParentalCode: {
          loading: true,
        },
      };
    case actionTypes.VALIDATE_ADULT_CODE_SUCCESS:
      return {
        ...state,
        validateParentalCode: {
          loading: false,
          meta: action.meta,
          data: action.payload,
        },
      };
    case actionTypes.VALIDATE_ADULT_CODE_ERROR:
      return {
        ...state,
        validateParentalCode: {
          loading: false,
          meta: action.meta,
          data: action.payload,
        },
      };
    case actionTypes.SET_PURCHASE_CODE_PENDING: {
      return {
        ...state,
        purchaseCode: {
          ...state.purchaseCode,
          updating: true,
        },
      };
    }
    case actionTypes.SET_PURCHASE_CODE_SUCCESS: {
      return {
        ...state,
        purchaseCode: {
          ...state.purchaseCode,
          updating: false,
        },
      };
    }
    case actionTypes.SET_PURCHASE_CODE_ERROR: {
      return {
        ...state,
        purchaseCode: {
          ...state.purchaseCode,
          updating: false,
        },
      };
    }
    case actionTypes.VALIDATE_PURCHASE_CODE_PENDING:
      return {
        ...state,
        validatePurchaseCode: {
          loading: true,
        },
      };
    case actionTypes.VALIDATE_PURCHASE_CODE_SUCCESS:
      return {
        ...state,
        validatePurchaseCode: {
          loading: false,
          meta: action.meta,
          data: action.payload,
        },
      };
    case actionTypes.VALIDATE_PURCHASE_CODE_ERROR:
      return {
        ...state,
        validatePurchaseCode: {
          loading: false,
          meta: action.meta,
          data: action.payload,
        },
      };
    case actionTypes.VALIDATE_ACCOUNT_PENDING:
      return {
        ...state,
        validateAccount: {
          loading: true,
        },
      };
    case actionTypes.VALIDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        validateAccount: {
          loading: false,
          meta: action.meta,
          data: action.payload,
          error: null,
        },
      };
    case actionTypes.VALIDATE_ACCOUNT_ERROR:
      return {
        ...state,
        validateAccount: {
          loading: false,
          meta: action.meta,
          error: action.payload,
          data: null,
        },
      };
    case actionTypes.CHANGE_DETAILS_PENDING:
      return {
        ...state,
        changeDetails: {
          loading: true,
        },
      };
    case actionTypes.CHANGE_DETAILS_SUCCESS:
      return {
        ...state,
        changeDetails: {
          loading: false,
          meta: action.meta,
          data: action.payload,
        },
      };
    case actionTypes.CHANGE_DETAILS_ERROR:
      return {
        ...state,
        changeDetails: {
          loading: false,
          meta: action.meta,
          data: action.payload,
        },
      };
    case actionTypes.CHANGE_ADDRESS_PENDING:
      return {
        ...state,
        changeAddress: {
          loading: true,
        },
      };
    case actionTypes.CHANGE_ADDRESS_SUCCESS:
      return {
        ...state,
        changeAddress: {
          loading: false,
          meta: action.meta,
          data: action.payload,
        },
      };
    case actionTypes.CHANGE_ADDRESS_ERROR:
      return {
        ...state,
        changeAddress: {
          loading: false,
          meta: action.meta,
          data: action.payload,
        },
      };
    case actionTypes.REMOVE_DEVICE_PENDING:
      return {
        ...state,
        casRemoveDevice: {
          loading: true,
        },
      };
    case actionTypes.REMOVE_DEVICE_SUCCESS:
      return {
        ...state,
        casRemoveDevice: {
          loading: false,
          meta: action.meta,
          data: action.payload,
        },
      };
    case actionTypes.REMOVE_DEVICE_ERROR:
      return {
        ...state,
        casRemoveDevice: {
          loading: false,
          meta: action.meta,
          data: action.payload,
        },
      };
    case actionTypes.ADD_DEVICE_PENDING:
      return {
        ...state,
        casAddDevice: {
          loading: true,
        },
      };
    case actionTypes.ADD_DEVICE_SUCCESS:
      return {
        ...state,
        casAddDevice: {
          loading: false,
          meta: action.meta,
          data: action.payload,
        },
      };
    case actionTypes.ADD_DEVICE_ERROR:
      return {
        ...state,
        casAddDevice: {
          loading: false,
          meta: action.meta,
          data: action.payload,
        },
      };
    case actionTypes.PROFILE_PENDING:
      return {
        ...state,
        profile: {
          loading: true,
        },
      };
    case actionTypes.PROFILE_SUCCESS:
      return {
        ...state,
        profile: {
          loading: false,
          meta: action.meta,
          data: action.payload.profiles,
        },
      };
    case actionTypes.PROFILE_ERROR:
      return {
        ...state,
        profile: {
          loading: false,
          meta: action.meta,
          data: action.payload,
        },
      };
    case actionTypes.AVATARS_PENDING:
      return {
        ...state,
        avatars: {
          loading: true,
        },
      };
    case actionTypes.AVATARS_SUCCESS:
      return {
        ...state,
        avatars: {
          loading: false,
          meta: action.meta,
          data: action.payload.avatars,
        },
      };
    case actionTypes.AVATARS_ERROR:
      return {
        ...state,
        avatars: {
          loading: false,
          meta: action.meta,
          data: action.payload,
        },
      };
    case actionTypes.POST_PROFILE_PENDING:
      return {
        ...state,
        createProfile: {
          loading: true,
        },
      };
    case actionTypes.POST_PROFILE_SUCCESS:
      return {
        ...state,
        createProfile: {
          loading: false,
          meta: action.meta,
          data: action.payload.profiles,
        },
      };
    case actionTypes.POST_PROFILE_ERROR:
      return {
        ...state,
        createProfile: {
          loading: false,
          meta: action.meta,
          data: action.payload,
        },
      };
    case actionTypes.PUT_PROFILE_PENDING:
      return {
        ...state,
        updateProfile: {
          loading: true,
        },
      };
    case actionTypes.PUT_PROFILE_SUCCESS:
      return {
        ...state,
        updateProfile: {
          loading: false,
          meta: action.meta,
          data: action.payload,
        },
      };
    case actionTypes.PUT_PROFILE_ERROR:
      return {
        ...state,
        updateProfile: {
          loading: false,
          meta: action.meta,
          data: action.payload,
        },
      };
    case actionTypes.DELETE_PROFILE_PENDING:
      return {
        ...state,
        deleteProfile: {
          loading: true,
        },
      };
    case actionTypes.DELETE_PROFILE_SUCCESS:
      return {
        ...state,
        deleteProfile: {
          loading: false,
          meta: action.meta,
          data: action.payload,
        },
      };
    case actionTypes.DELETE_PROFILE_ERROR:
      return {
        ...state,
        deleteProfile: {
          loading: false,
          meta: action.meta,
          data: action.payload,
        },
      };
    case actionTypes.PROFILE_ACTIVE_PENDING:
      return {
        ...state,
        getActiveProfile: {
          loading: action.payload.triggerLoading,
        },
      };
    case actionTypes.PROFILE_ACTIVE_LOADING_COMPLETED:
      return {
        ...state,
        getActiveProfile: {
          loading: false,
        },
      };
    case actionTypes.PROFILE_ACTIVE_SUCCESS:
      return {
        ...state,
        getActiveProfile: {
          loading: false,
          meta: action.meta,
          data: action.payload,
        },
      };
    case actionTypes.PROFILE_ACTIVE_ERROR:
      return {
        ...state,
        getActiveProfile: {
          loading: false,
          meta: action.meta,
          data: action.payload,
        },
      };
    case actionTypes.SET_PROFILE_ACTIVE_PENDING:
      return {
        ...state,
        setActiveProfile: {
          loading: true,
        },
      };
    case actionTypes.SET_PROFILE_ACTIVE_SUCCESS:
      return {
        ...state,
        setActiveProfile: {
          loading: false,
          meta: action.meta,
          data: action.payload,
        },
      };
    case actionTypes.SET_PROFILE_ACTIVE_ERROR:
      return {
        ...state,
        setActiveProfile: {
          loading: false,
          meta: action.meta,
          data: action.payload,
        },
      };
    case actionTypes.SET_ON_SIGNUP_STEPS:
      return {
        ...state,
        onSignupSteps: action.onSignupSteps,
      };
    case actionTypes.RESET_GLOBAL_STATE:
      return initialState;
    default:
      return state;
  }
};
