import Button, { ButtonType } from "components/Button";
import Input, { InputType } from "components/Input";
import consts from "consts/consts";
import { getFromLocal } from "helpers/localStorage";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { TucanoActions, TucanoSelectors } from "web-api/main";

import style from "./style.module.css";

class VoucherZone extends Component {
  state = {
    voucherCode: this.props.value,
    validVoucher: undefined,
    optionVoucher: undefined,
  };

  errorAPI = {
    "-10": "Voucher is expired according validity rules of voucher set",
    "-11": "Voucher checksum is invalid",
    "-30": "Voucher is already burnt by specified sale",
    "-31": "Voucher is burnt by another sale",
    "-1608": "Voucher code is empty",
    "-1609": "Voucher code is invalid",
    // API Message: "Invalid voucher"
    "-911": "Invalid voucher code entered. Please check and try again",
    // API message: "Coupon is not valid"
    "-15008":
      "This coupon is no longer valid! Please use another one or proceed without coupon",
    // API message: Coupon is expired
    "-15007":
      "This coupon is no longer valid! Please use another one or proceed without coupon",
    // API message: "Invalid voucher"
    "-1": "No matching voucher found",
  };

  getErrorAPI(code) {
    if (this.errorAPI[code]) {
      return this.errorAPI[code];
    } else {
      return "An error occured,Please retry later";
    }
  }

  onValueChange(voucherCode) {
    const { callback } = this.props;

    this.setState({ voucherCode: voucherCode });
    callback(voucherCode);
  }

  handleOnValidateVoucher() {
    this.setState({ validVoucher: undefined });

    this.props
      .dispatch(TucanoActions.callValidateVoucher(this.state.voucherCode))
      .then((result) => {
        if (result && result.status) {
          this.handleOnApplyVoucher();
          this.setState({
            validVoucher: true,
          });
        }
      });
  }

  validateVoucherForOffer = async (result) => {
    const { subscriptionItems, callback } = this.props;
    let validVoucher = false;
    let optionVoucher = undefined;

    if (result.options && result.options.length > 0) {
      result.options.forEach((option) => {
        subscriptionItems.forEach((item) => {
          if (option.idOption === item.getIdOption()) {
            validVoucher = true;
            optionVoucher = option;
            return;
          }
        });
      });
    }

    await this.setState({
      validVoucher: validVoucher,
      optionVoucher: optionVoucher,
    });
    callback(this.state.voucherCode, validVoucher, optionVoucher);
  };

  async handleOnApplyVoucher() {
    const { subscriptionItems } = this.props;
    const productId = getFromLocal("productId") || consts.productId;

    if (subscriptionItems[0].offerType === "transactional") {
      this.props
        .dispatch(
          TucanoActions.getTvodOffers(
            getFromLocal("languageId"),
            productId,
            this.state.voucherCode
          )
        )
        .then(async (result) => {
          await this.validateVoucherForOffer(result);
        });
    } else if (subscriptionItems[0].offerType === "ppv") {
      this.props
        .dispatch(
          TucanoActions.getPpvOffers(
            getFromLocal("languageId"),
            productId,
            this.state.voucherCode
          )
        )
        .then(async (result) => {
          await this.validateVoucherForOffer(result);
        });
    } else {
      this.props
        .dispatch(
          TucanoActions.getAvailableOffers(
            getFromLocal("languageId"),
            productId,
            this.state.voucherCode
          )
        )
        .then(async (result) => {
          await this.validateVoucherForOffer(result);
        });
    }
  }

  render() {
    const { rootClassName, voucherError, t } = this.props;

    return (
      <div className={style.container + " " + rootClassName}>
        <span className={style.label}>
          <Trans>Got a voucher code</Trans>
        </span>
        <div className={style.inputApplyButton}>
          <Input
            type={InputType.TEXT}
            onChange={(value) => this.onValueChange(value)}
            value={this.state.voucherCode}
            rootClassName={style.inputVoucherCode}
            placeholder={t("Enter your code here")}
          />
          <Button
            rootClassName={style.applyButton}
            type={
              this.state.voucherCode ? ButtonType.BORDERED : ButtonType.DISABLED
            }
            onClick={this.handleOnValidateVoucher.bind(this)}
          >
            <Trans>Apply code</Trans>
          </Button>
        </div>
        {voucherError !== undefined && (
          <span className={style.voucherError}>
            {t(this.getErrorAPI(voucherError.code))}
          </span>
        )}
        {this.state.validVoucher !== undefined &&
          this.state.validVoucher &&
          !voucherError && (
            <Fragment>
              <span className={style.voucherAppliedInfo}>
                <Trans>Your voucher will be applied at the payment</Trans>
              </span>
            </Fragment>
          )}
      </div>
    );
  }
}

VoucherZone.defaultProps = {
  subscriptionItems: [],
  value: "",
};

VoucherZone.propTypes = {
  subscriptionItems: PropTypes.array,
  value: PropTypes.string,
};

export default compose(
  connect((state) => {
    return {
      availableOffersVoucher: TucanoSelectors.getAvailableOffersVoucher(state),
      voucherError: state.subscription.validateVoucher.error,
      voucherData: state.subscription.validateVoucher.data,
    };
  }),
  withTranslation()
)(VoucherZone);
