import { setDiscoverMode } from "actions/session";
import classnames from "classnames";
import BannerFull from "components/BannerFull";
import Button, { ButtonType } from "components/Button";
import ContentWrapper from "components/ContentWrapper";
import EditorialBanner from "components/EditorialBanner";
import Arrow from "components/Icons/Arrow";
import CheckIcon from "components/Icons/Check";
import Sad from "components/Icons/Sad";
import { LinkDuo } from "components/LinkDuo";
import Loader from "components/Loader";
import Modal from "components/Modal";
import Slide from "components/Slide";
import SlideBanner from "components/SlideBanner";
import SlideShow from "components/SlideShow";
import Strate from "components/Strate";
import ThumbnailCircle from "components/ThumbnailCircle";
import ThumbnailContinueWatching from "components/ThumbnailContinueWatching";
import ThumbnailCover from "components/ThumbnailCover";
import ThumbnailPoster from "components/ThumbnailPoster";
import ThumbnailSquare from "components/ThumbnailSquare";
import ThumbnailTotem from "components/ThumbnailTotem";
import consts from "consts/consts";
import responsiveConf from "consts/responsive";
import PageAbstract from "containers/PageAbstract";
import { getFromLocal } from "helpers/localStorage";
import { getRealSlug } from "helpers/url";
import { getQueryVariable } from "helpers/url";
import React, { Fragment } from "react";
import { Trans, withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import { TucanoActions, TucanoSelectors } from "web-api/main";

import style from "./style.module.css";

class PageHome extends PageAbstract {
  state = {
    showValidationModal: false,
    currentSlideIndex: 0,
    slice: 4,
    items: [],
    hasMore: true,
    componentReady: false,
  };
  static fetch(dispatch, isConnected, _params, prevIsConnectedValue) {
    const languageId = getFromLocal("languageId") ?? consts.languageId;
    const homePageType = isConnected
      ? consts.homePage_connected
      : consts.homePage_anonymous;
    const isConnectionStatusChanged = prevIsConnectedValue != isConnected; //this check is added to verify if the loader in the home page should be displayed or not

    Promise.all([
      dispatch(
        TucanoActions.getHome(
          languageId,
          homePageType,
          isConnectionStatusChanged
        )
      ),
      dispatch(
        TucanoActions.getChannels(
          undefined,
          undefined,
          languageId,
          isConnectionStatusChanged
        )
      ),
      dispatch(
        TucanoActions.getEPG(new Date(), languageId, isConnectionStatusChanged)
      ),
    ]);
  }

  toggleValidationModal = () => {
    this.setState({ showValidationModal: !this.state.showValidationModal });
  };

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.strates) !== JSON.stringify(this.props.strates)
    ) {
      this.fetchMoreData();
    }
  }

  async componentDidMount() {
    const { t } = this.props;
    const search = new URLSearchParams(window.location.search);

    const state = search.get("state") || "";
    const code = search.get("code") || "";

    if (consts.guestModeEnabled && !this.props.isConnected) {
      this.props.dispatch(setDiscoverMode(true));
    }

    if (
      !consts.guestModeEnabled &&
      !this.props.isConnected &&
      !code &&
      !state
    ) {
      return this.props.history.push(consts.routes.signIn.url);
    }
    // if (!slides || !strates) {
    this.constructor.fetch(
      this.props.dispatch,
      this.props.isConnected,
      null,
      this.props.isConnected
    );
    // }
    document.title = `${t(this.props.route.title)} - ${consts.name}`;
    if (consts.appTitle) {
      document.title = `${consts.appTitle} - ${t(this.props.route.title)}`;
    }

    if (consts.metaData.length > 0) {
      document.getElementsByTagName("meta")[3].content =
        consts.metaData[0].content;
      document.getElementsByTagName("meta")[4].content =
        consts.metaData[1].content;
      document.getElementsByTagName("meta")[5].content =
        consts.metaData[2].content;
    }
    if (this.props.isConnected === true) {
      this.props.dispatch(
        TucanoActions.getActiveAssets({
          languageId: getFromLocal("languageId"),
          allAssets: 1,
          count: 1000,
          contentTypeFilter: "movie+series",
        })
      );
    }
    if (this.props.location.search.includes("?token")) {
      const email = getQueryVariable(this.props.location.search, "email");
      const token = getQueryVariable(this.props.location.search, "token");
      if (this.state.showValidationModal === false) {
        await this.props
          .dispatch(TucanoActions.validateAccount(email, token))
          .then((_res) => {
            this.toggleValidationModal();
          });
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch(TucanoActions.resetActiveAssets());
    this.props.dispatch(TucanoActions.resetEPG());
  }

  subscriptionModal(asset) {
    if (this.props.subscriptionModal) {
      this.props.subscriptionModal(asset);
    }
  }

  updateCurrentIndex = (index) => {
    this.setState({ currentSlideIndex: index });
  };

  fetchMoreData = () => {
    const { strates } = this.props;
    if (strates) {
      let data = strates.slice(0, this.state.slice);
      this.setState({
        items: data,
      });
      if (this.state.items.length === strates.length) {
        this.setState({ hasMore: false });
      } else {
        this.setState((prevState) => {
          return {
            items: strates.slice(0, prevState.slice),
            slice: prevState.slice + 4,
          };
        });
      }
    }
  };

  render() {
    const {
      isLoading,
      isChannelsLoading,
      isEPGLoading,
      viewport,
      liveEPG,
      isConnected,
      accountValidationError,
      activeProfile,
    } = this.props;
    const { currentSlideIndex, hasMore, items } = this.state;

    const thumbnailSize = responsiveConf.find((item) => {
      return item.name === viewport.type;
    });
    let isPhoneSize = false;
    if (thumbnailSize.name === "PHONE") {
      isPhoneSize = true;
    }
    const direction = getFromLocal("layoutDirection");
    return (
      <Fragment>
        {(isLoading || isChannelsLoading || isEPGLoading) && (
          <Loader centered={true} />
        )}
        {!isLoading && !isChannelsLoading && !isEPGLoading && (
          <InfiniteScroll
            pageStart={0}
            loadMore={this.fetchMoreData.bind(this)}
            hasMore={hasMore}
            loader={<Loader centered={false} key={0} />}
            threshold={250}
          >
            {items &&
              items.length > 0 &&
              items.map((item, index) => {
                let strateIndex = index;
                if (
                  item.getItems() &&
                  item.getItems().length === 1 &&
                  item.getItems()[0] !== undefined &&
                  (item.getItems()[0].getDesignMode().includes("fullscreen") ||
                    item.getItems()[0].getDesignMode() === "banner" ||
                    item.getItems()[0].getDesignMode() === "promo-left" ||
                    item.getItems()[0].getDesignMode() === "promo-right" ||
                    item.getItems()[0].getDesignMode() === "banner-full")
                ) {
                  const url = getRealSlug(item.getUrl(this.props.baseUrl));
                  return (
                    <Fragment key={index}>
                      {item.getItems()[0].getDesignMode() === "banner" &&
                      url !== "/" ? (
                        url.includes("undefined") ? (
                          <div>
                            <h2
                              className={classnames(style.title, {
                                [style.titleRTL]: direction === "rtl",
                              })}
                            >
                              <>{item.getTitle()}</>
                              {item.getItems().length > 1 && (
                                <div
                                  className={classnames(style.seeAll, {
                                    [style.seeAllLTR]: direction !== "rtl",
                                    [style.seeAllRTL]: direction === "rtl",
                                  })}
                                >
                                  <Trans>See All</Trans>
                                  <Arrow
                                    className={classnames(style.seeAllIcon, {
                                      [style.seeAllIconRTL]:
                                        direction === "rtl",
                                    })}
                                  />
                                </div>
                              )}
                            </h2>
                          </div>
                        ) : (
                          <LinkDuo className={style.link} to={url}>
                            <h2
                              className={classnames(style.title, {
                                [style.titleRTL]: direction === "rtl",
                              })}
                            >
                              <>{item.getTitle()}</>
                              {item.getItems().length > 1 && (
                                <div
                                  className={classnames(style.seeAll, {
                                    [style.seeAllLTR]: direction !== "rtl",
                                    [style.seeAllRTL]: direction === "rtl",
                                  })}
                                >
                                  <Trans>See All</Trans>
                                  <Arrow
                                    className={classnames(style.seeAllIcon, {
                                      [style.seeAllIconRTL]:
                                        direction === "rtl",
                                    })}
                                  />
                                </div>
                              )}
                            </h2>
                          </LinkDuo>
                        )
                      ) : (
                        !item
                          .getItems()[0]
                          .getDesignMode()
                          .includes("fullscreen") && (
                          <LinkDuo to={url}>
                            <h2
                              className={classnames(style.title, {
                                [style.titleRTL]: direction === "rtl",
                              })}
                            >
                              {item.getTitle()}
                            </h2>
                          </LinkDuo>
                        )
                      )}
                      {item.getItems().map((asset) => {
                        if (asset.getDesignMode() === "banner") {
                          return (
                            <SlideBanner
                              key={asset.id}
                              asset={asset}
                              liveEPG={liveEPG}
                              thumbnailSize={thumbnailSize}
                              profile={activeProfile}
                            />
                          );
                        } else if (
                          asset.getDesignMode() === "promo-left" ||
                          asset.getDesignMode() === "promo-right"
                        ) {
                          return (
                            <EditorialBanner
                              key={asset.id}
                              asset={asset}
                              liveEPG={liveEPG}
                              thumbnailSize={thumbnailSize}
                              direction={asset.getDesignMode()}
                              profile={activeProfile}
                            />
                          );
                        } else if (asset.getDesignMode() === "banner-full") {
                          return (
                            <BannerFull
                              key={asset.id}
                              asset={asset}
                              liveEPG={liveEPG}
                              thumbnailSize={thumbnailSize}
                              profile={activeProfile}
                            />
                          );
                        } else {
                          return (
                            <Slide
                              key={asset.id}
                              asset={asset}
                              isPlayable={true}
                              liveEPG={liveEPG}
                              subscriptionModal={this.subscriptionModal.bind(
                                this
                              )}
                              showTopGradient={strateIndex === 0}
                              showTrailer="homeSlides"
                              profile={activeProfile}
                            />
                          );
                        }
                      })}
                    </Fragment>
                  );
                }
                if (
                  item.getItems() &&
                  item.getItems().length > 1 &&
                  item.getItems()[0] !== undefined &&
                  item.getItems()[0].getDesignMode() &&
                  (item.getItems()[0].getDesignMode().includes("fullscreen") ||
                    item.getItems()[0].getDesignMode() === "banner" ||
                    item.getItems()[0].getDesignMode() === "promo-left" ||
                    item.getItems()[0].getDesignMode() === "promo-right" ||
                    item.getItems()[0].getDesignMode() === "banner-full")
                ) {
                  return (
                    <Fragment key={index}>
                      {item.getItems()[0].getDesignMode() === "banner" && (
                        <h2
                          className={classnames(style.title, {
                            [style.titleRTL]: direction === "rtl",
                          })}
                        >
                          {item.getTitle()}
                        </h2>
                      )}
                      <SlideShow
                        key={index}
                        pagination={true}
                        autoplay={consts.carousel.autoplay}
                        interval={consts.carousel.interval}
                        onChangeIndex={this.updateCurrentIndex.bind(this)}
                      >
                        {item.getItems().map((asset, index) => {
                          if (asset.getDesignMode() === "banner") {
                            return (
                              <Fragment key={index}>
                                <SlideBanner
                                  key={index}
                                  asset={asset}
                                  liveEPG={liveEPG}
                                  thumbnailSize={thumbnailSize}
                                  profile={activeProfile}
                                />
                              </Fragment>
                            );
                          } else if (asset.getDesignMode() === "banner-full") {
                            return (
                              <BannerFull
                                key={asset.id}
                                asset={asset}
                                liveEPG={liveEPG}
                                thumbnailSize={thumbnailSize}
                                profile={activeProfile}
                              />
                            );
                          } else if (
                            asset.getDesignMode() === "promo-left" ||
                            asset.getDesignMode() === "promo-right"
                          ) {
                            return (
                              <EditorialBanner
                                key={asset.id}
                                asset={asset}
                                liveEPG={liveEPG}
                                thumbnailSize={thumbnailSize}
                                direction={asset.getDesignMode()}
                                profile={activeProfile}
                              />
                            );
                          } else {
                            return (
                              <Slide
                                key={index}
                                asset={asset}
                                isPlayable={true}
                                liveEPG={liveEPG}
                                subscriptionModal={this.subscriptionModal.bind(
                                  this
                                )}
                                index={index}
                                currentSlideIndex={currentSlideIndex}
                                fta={asset.fta}
                                isConnected={isConnected}
                                isPhoneSize={isPhoneSize}
                                showTopGradient={strateIndex === 0}
                                showTrailer="homeSlides"
                                profile={activeProfile}
                              />
                            );
                          }
                        })}
                      </SlideShow>
                    </Fragment>
                  );
                }
                return (
                  <ContentWrapper key={index}>
                    <Strate key={index} item={item}>
                      {item.getItems().map((item, index) => {
                        if (item.getDesignMode() === "continue-watching") {
                          return (
                            <ThumbnailContinueWatching
                              style={{
                                maxWidth: thumbnailSize.cover.width,
                                minWidth: thumbnailSize.cover.width,
                              }}
                              key={index}
                              asset={item}
                              usePosterImage={true}
                              onlyFavIcon={true}
                              onlyPlay={false}
                              fta={item.fta}
                              isConnected={isConnected}
                              thumbnailSize={thumbnailSize}
                              subscriptionModal={this.subscriptionModal.bind(
                                this
                              )}
                              showOnlyLogo={true}
                              profile={activeProfile}
                            />
                          );
                        }
                        if (item.getDesignMode().includes("square")) {
                          return (
                            <ThumbnailSquare
                              key={index}
                              item={item}
                              size={thumbnailSize}
                              fta={item.fta}
                              isConnected={isConnected}
                              large={item.getDesignMode().includes("full")}
                              linkToPlayer={true}
                              subscriptionModal={this.subscriptionModal.bind(
                                this
                              )}
                              showFavorite={true}
                              profile={activeProfile}
                            />
                          );
                        }
                        if (item.getDesignMode() === "circle") {
                          return (
                            <ThumbnailCircle
                              key={index}
                              item={item}
                              fta={item.fta}
                              usePosterImage={true}
                              size={thumbnailSize}
                              subscriptionModal={this.subscriptionModal.bind(
                                this
                              )}
                              isConnected={isConnected}
                              profile={activeProfile}
                            />
                          );
                        }
                        if (item.getDesignMode().includes("totem")) {
                          return (
                            <ThumbnailTotem
                              key={index}
                              asset={item}
                              fta={item.fta}
                              isConnected={isConnected}
                              size={thumbnailSize}
                              subscriptionModal={this.subscriptionModal.bind(
                                this
                              )}
                              profile={activeProfile}
                            />
                          );
                        }
                        if (item.getDesignMode().includes("cover")) {
                          return (
                            <ThumbnailCover
                              activeTrailer={true}
                              key={index}
                              asset={item}
                              fta={item.fta}
                              isConnected={isConnected}
                              size={thumbnailSize}
                              large={item.getDesignMode().includes("full")}
                              subscriptionModal={this.subscriptionModal.bind(
                                this
                              )}
                              profile={activeProfile}
                            />
                          );
                        }
                        return (
                          <ThumbnailPoster
                            activeTrailer={true}
                            key={index}
                            order={index + 1}
                            asset={item}
                            fta={item.fta}
                            isConnected={isConnected}
                            size={thumbnailSize}
                            large={item.getDesignMode().includes("large")}
                            subscriptionModal={this.subscriptionModal.bind(
                              this
                            )}
                            profile={activeProfile}
                          />
                        );
                      })}
                    </Strate>
                  </ContentWrapper>
                );
              })}
            {this.state.showValidationModal && (
              <Modal
                rightContent={{ canDismiss: this.toggleValidationModal }}
                rootClassName={style.modalValidate}
                overlay={true}
                buttons={
                  <Button
                    rootClassName={style.confirmButton}
                    type={ButtonType.NORMAL}
                    onClick={this.toggleValidationModal}
                  >
                    <Trans>Close</Trans>
                  </Button>
                }
              >
                {!accountValidationError && (
                  <>
                    <div className={style.iconContainer}>
                      <CheckIcon className={style.checkIcon} />
                    </div>
                    <h1 className={style.infoTitle}>
                      <Trans>Awesome</Trans> !
                    </h1>

                    <span className={style.contentMessage}>
                      <Trans>
                        Your account has been successfully validated,You can now
                        login on
                      </Trans>
                      {` ${consts.name}`}
                    </span>
                  </>
                )}

                {accountValidationError && (
                  <>
                    <div className={style.iconContainer}>
                      <Sad className={style.sadIcon} />
                    </div>
                    <h1 className={style.infoTitle}>
                      <Trans>Link has expired</Trans>
                    </h1>

                    <span className={style.contentMessage}>
                      <Trans>
                        We were not able to validate your account,Please try to
                        sign up again
                      </Trans>
                    </span>
                  </>
                )}
              </Modal>
            )}
          </InfiniteScroll>
        )}
      </Fragment>
    );
  }
}

export default compose(
  connect((state) => {
    return {
      isLoading: state.content.home.loading,
      isEPGLoading: state.content.epg.loading,
      isChannelsLoading: state.reference.channels.loading,
      isConnected: state.session.customerAuthToken !== undefined,
      viewport: state.ui.viewport,
      strates: TucanoSelectors.getHomeStrates(state),
      liveEPG: TucanoSelectors.getLive(state),
      accountValidated: state.account.validateAccount.data,
      accountValidationError: state.account.validateAccount.error,
      activeProfile: TucanoSelectors.getActiveProfile(state),
    };
  }),
  withTranslation()
)(withRouter(PageHome));
