import consts from "consts/consts";
import { getFromLocal } from "helpers/localStorage";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  authWithApple,
  authWithFacebook,
  authWithGoogle,
} from "../../helpers/SSOSocialAuth";
import style from "./style.module.css";
function SocialButton(props) {
  const [authUser, setAuthUser] = useState(false);
  const { type, Translate, t, isModal } = props;
  const dispatch = useDispatch();

  const getIcon = (type) => {
    switch (type.toLowerCase()) {
      case "apple":
        return "sso_apple";
      case "google":
        return "sso_google";
      case "facebook":
        return "sso_facebook";
      default:
        return "placeholder";
    }
  };

  function changeBackground(_e) {
    isModal
      ? (document.getElementById("img-modal-" + getIcon(type)).src =
          "/assets/socials/" + getIcon(type) + "_hover.svg")
      : (document.getElementById("img-" + getIcon(type)).src =
          "/assets/socials/" + getIcon(type) + "_hover.svg");
  }

  function changeBackgroundBack(_e) {
    isModal
      ? (document.getElementById("img-modal-" + getIcon(type)).src =
          "/assets/socials/" + getIcon(type) + ".svg")
      : (document.getElementById("img-" + getIcon(type)).src =
          "/assets/socials/" + getIcon(type) + ".svg");
  }

  const checkLoggedUserFacebook = () => {
    if (type.toLowerCase() === "facebook") {
      window?.FB?.getLoginStatus(({ authResponse }) => {
        if (authResponse) {
          // console.log("authenticate success", authResponse);
          FB.api(
            "/me",
            { fields: "email, first_name,last_name" },
            (response) => {
              if (response.error) {
                setAuthUser(false);
              } else {
                setAuthUser(response);
              }
            }
          );
        }
      });
    }
  };

  // eslint-disable-next-line no-unused-vars
  const checkLoggedUserGoogle = () => {
    // console.log(window.GoogleAuth);
    if (window.GoogleAuth.isSignedIn.get()) {
      // User is authorized and has clicked "Sign out" button.
      window.GoogleAuth.signOut();
    } else {
      // User is not signed in. Start Google auth flow.
      window.GoogleAuth.signIn()
        .then((_googleUser) => {
          // console.log("User signed in.");
          // console.log(googleUser.xu.lv);
        })
        .catch((error) => {
          // Google auth flow failed.
          // eslint-disable-next-line no-console
          console.error(error);
        });
    }
  };

  // eslint-disable-next-line no-unused-vars
  const checkLoggedUserApple = () => {};

  useEffect(() => {
    checkLoggedUserFacebook();
    //checkLoggedUserGoogle();
  }, []);

  const handleClick = (type) => {
    const isSafari = getFromLocal("isSafari");
    const client_id = isSafari ? consts.safariApiKey : consts.apiKey;
    switch (type.toLowerCase()) {
      case "facebook":
        authWithFacebook(dispatch);
        break;
      case "apple":
        authWithApple(dispatch, client_id);
        break;
      case "google":
        authWithGoogle(dispatch, client_id);
        break;
      default:
      // console.log(type + " not managed");
    }
  };

  return (
    <button
      id={`social-${type}`}
      onMouseOver={changeBackground}
      onMouseOut={changeBackgroundBack}
      className={style.button}
      onClick={() => handleClick(type)}
    >
      <img
        className={style.imgLogo}
        id={isModal ? "img-modal-" + getIcon(type) : "img-" + getIcon(type)}
        src={"/assets/socials/" + getIcon(type) + ".svg"}
      />
      <p className={style.text}>
        {authUser && (
          <>
            <Translate t={t}>Continue as </Translate>{" "}
            <span className={style.capitalize}>
              {authUser.first_name} {authUser.last_name}
            </span>
          </>
        )}
        {!authUser && (
          <>
            <Translate t={t}>Log in with</Translate>{" "}
            <span className={style.capitalize}>{type}</span>
          </>
        )}
      </p>
    </button>
  );
}

export default withTranslation()(SocialButton);

SocialButton.defaultProps = {
  type: "",
  isModal: false,
  size: "",
  onClick: () => {},
  disabled: false,
  loading: false,
};

SocialButton.propTypes = {
  currentStep: PropTypes.number,
  currentSubStep: PropTypes.number,
  steps: PropTypes.array,
};
