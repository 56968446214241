import classnames from "classnames";
import { isConnectedFromLocalISP } from "helpers/localIsp";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";

import style from "./style.module.css";

export const ImageRatio = {
  COVER: "COVER",
  POSTER: "POSTER",
  SQUARE: "SQUARE",
  CIRCLE: "CIRCLE",
  TOTEM: "TOTEM",
  BANNER: "BANNER",
};

export const PlaceholderType = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
  TRANSPARENT: "TRANSPARENT",
  HIDDEN: "HIDDEN",
  PROFILE: "PROFILE",
  SEARCH: "SEARCH",
};

export default class Image extends Component {
  constructor(props) {
    super(props);
    this.state = {
      srcPreview: undefined,
      intersected: false,
      error: false,
      lqipLoaded: false,
      fullsizeLoaded: false,
    };
    this.observer = null;
  }

  componentDidMount() {
    if (this.props.src) {
      // the app crash in firefox if the url is invalid
      if (this.props.src !== "#") {
        let url = new URL(this.props.src);
        var query_string = url.search;
        var search_params = new URLSearchParams(query_string);

        search_params.set("format_w", "1");
        search_params.set("format_h", "1");

        url.search = search_params.toString();

        let srcPreview = url.toString();

        this.setState({ srcPreview: srcPreview });
      }

      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const { isIntersecting, intersectionRatio } = entry;

          if (isIntersecting === true || intersectionRatio > 0) {
            this.setState({ intersected: true });
            if (this.observer) {
              this.observer.disconnect();
            }
            this.observer = null;
          }
        });
      });

      this.observer.observe(this.imgRef);
    }
  }

  render() {
    const {
      src,
      height,
      width,
      rootClassName,
      ratio,
      border,
      alt,
      placeholder,
      blur,
      ...props
    } = this.props;
    const { srcPreview, intersected, error, fullsizeLoaded, lqipLoaded } =
      this.state;

    const isLocalIsp = isConnectedFromLocalISP();

    const styles = {
      lqip: {
        width: "100%",
        filter: "blur(1px)",
        opacity: 1,
        transition: "all 0.5s ease-in",
      },
      fullsize: {
        position: "absolute",
        top: "0px",
        left: "0px",
        transition: "all 0.5s ease-in",
        objectFit: "contain",
      },
    };

    // When the fullsize image is loaded, fade out the LQIP
    if (fullsizeLoaded) {
      styles.lqip.opacity = 0;
    }

    return (
      <Fragment>
        <div
          className={classnames(style.image, rootClassName, {
            [style.cover]: ratio === ImageRatio.COVER,
            [style.poster]: ratio === ImageRatio.POSTER,
            [style.square]: ratio === ImageRatio.SQUARE,
            [style.circle]: ratio === ImageRatio.CIRCLE,
            [style.totem]: ratio === ImageRatio.TOTEM,
            [style.banner]: ratio === ImageRatio.BANNER,
            [style.border]: border,
            [style.blur]: blur,
          })}
        >
          <div
            className={classnames(style.placeholder, {
              [style.placeholderLogo]: !isLocalIsp,
              [style.placeholderLocal]: isLocalIsp,
              [style.placeholderProfile]:
                placeholder === PlaceholderType.PROFILE,
              [style.placeholderPrimary]:
                placeholder === PlaceholderType.PRIMARY,
              [style.placeholderSecondary]:
                placeholder === PlaceholderType.SECONDARY,
              [style.placeholderSearch]: placeholder === PlaceholderType.SEARCH,
              [style.placeholderLoading]:
                src && !lqipLoaded && !fullsizeLoaded && !error,
              [style.placeholderTransparent]:
                placeholder === PlaceholderType.TRANSPARENT,
              [style.hidden]:
                placeholder === PlaceholderType.HIDDEN ||
                (fullsizeLoaded && !error),
            })}
          />
          <img
            className={classnames({
              [style.hidden]: error === true,
            })}
            style={styles.fullsize}
            src={intersected && src ? src : ""}
            alt={alt}
            height={height}
            width={width}
            onLoad={() => {
              this.setState({ fullsizeLoaded: true });
            }}
            onError={() => {
              if (intersected && !error) this.setState({ error: true });
            }}
            {...props}
          />
          <img
            className={classnames({
              [style.hidden]: error === true,
            })}
            style={styles.lqip}
            src={
              intersected && src && srcPreview !== undefined ? srcPreview : ""
            }
            alt={alt}
            height={height}
            width={width}
            ref={(elem) => (this.imgRef = elem)}
            onLoad={() => {
              this.setState({ lqipLoaded: true });
            }}
            // onError={() => {
            //   if (intersected && !error) this.setState({ error: true });
            // }}
            {...props}
          />
        </div>
        {border && (
          <div
            className={classnames(style.placeholder, style.borderImg, {
              [style.borderImgLoaded]: intersected && lqipLoaded,
            })}
          >
            <img
              className={classnames({
                [style.cover]: ratio === ImageRatio.COVER,
                [style.poster]: ratio === ImageRatio.POSTER,
                [style.square]: ratio === ImageRatio.SQUARE,
                [style.circle]: ratio === ImageRatio.CIRCLE,
                [style.totem]: ratio === ImageRatio.TOTEM,
                [style.hidden]: error === true,
              })}
              src={
                intersected && src && srcPreview !== undefined ? srcPreview : ""
              }
              alt={alt}
              height={height}
              width={width}
            />
          </div>
        )}
      </Fragment>
    );
  }
}

Image.defaultProps = {
  src: "",
  alt: "",
  rootClassName: "",
  height: "100%",
  width: "100%",
  ratio: ImageRatio.POSTER,
  border: false,
};

Image.propTypes = {
  height: PropTypes.any,
  rootClassName: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.any,
  ratio: PropTypes.oneOf(Object.values(ImageRatio)),
  placeholder: PropTypes.oneOf(Object.values(PlaceholderType)).isRequired,
  border: PropTypes.bool,
};
